<template>
	<div class="pt-5 pb-2 bg-black">
		<b-container fluid class="px-main">
			<b-row>
				<b-col lg="6" class="pt-5">
					<Logo class="mb-3 pe-none position-absolute t-0" />
					<p>PKF Integrity works with private and public sector organisations to manage their {{term}} reporting channels. We support best practice in the identification and response to unethical behaviour, misconduct, fraud and corruption. Ethical behaviour is not just our business; it’s a fundamental value. PKF Integrity is a proud member of PKF International, a global network of accountancy and compliance firms.</p>
				</b-col>
				<b-col lg="5" offset-lg="1" class="pt-3 pt-lg-5">
					<!-- <b-button @click="$router.push('/step1')">Submit report</b-button><br> -->
					<router-link to="/step1">Submit Report</router-link><br>
					<router-link class="mt-2" to="/login">Login</router-link><br>
					<router-link class="mt-2" to="/help">Get Help</router-link>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="mt-4 mb-2">Copyright {{year}} PKF Integrity Services (BMNS) Pty Ltd - All Rights Reserved</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import { Logo } from '@/components/ui'
export default {
	name: 'Footer',
	components: { Logo },
	computed: {
		year() {
			return new Date().getFullYear()
		}
	}
} 
</script>

<style lang="scss" scoped>
a{
	color: $light;
	display: inline-block;
}
</style>