import Vue from 'vue'
import '@/utils/dateProtypes.js'

Vue.filter('capitalise', value => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('formatDate', (value, arg) => {
	const d = new Date(value)
	if (isNaN(d)) return value
	return d.format(arg)
})

Vue.filter('format1800', value => {
	if (!value) return ''
	return String(value).replace(/[^0-9]/g, '')
		.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
})