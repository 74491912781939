<template>
	<div class="position-relative video-player">
		<video ref="player" controls poster="/img/video.jpg" width="100%" @play="playing = true" @pause="playing = false">
			<source :src="src" type="video/mp4">
		</video>
		<div v-if="!playing" class="abs-full player-control" @click="$refs.player.play()"><i class="gg-play-button-o"></i></div>
		<!-- <video src=""></video> -->
	</div>
</template>

<script>
export default {
	props: ['src'],
	data() {
		return {
			playing: false
		}
	},
}
</script>

<style lang="scss">
.player-control{
	transition: opacity .2s;
	background: transparent;
	cursor: pointer;
}
.video-player:hover .player-control{
	opacity: 0.6;
}
 .gg-play-button-o {
	position: absolute;
	top: 50%;
	left: 50%;
	box-sizing: border-box;
	display: block;
	transform: translate(-50%, -100%) scale(3);
	width: 22px;
	height: 22px;
	border: 2px solid white;
	border-radius: 20px
}

.gg-play-button-o::before {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 0;
	height: 10px;
	border-top: 5px solid transparent;
	border-bottom: 5px solid transparent;
	border-left: 6px solid white;
	top: 4px;
	left: 7px
} 
</style>