import { canChat } from "@/mixins";
import {sanitize} from 'dompurify';

export default {
	data() {
		return {
			timeout: 0,
		}
	},
	computed: {
		disclosureId() {
			return this.$store.state.disclosureId 
		},
		connected() {
			if (!this.hasChat) return true
			return this.$store.state.chat.isStarted
		}
	},
	mixins: [canChat],
	created() {
		if (!this.connected && this.hasChat) {
			this.$socket.start({
				log: true // Active only in development for debugging.
			});
			this.start()
		}
	},
	methods: {
		async start() {
			await this.$socket.invoke("JoinGroups", {disclosureId: this.disclosureId})
			this.$store.commit('chat/set', {isStarted: true})
		},
		send() {
			if (!this.connected) return
			let {disclosureId, message} = this
			message = sanitize(message, {ALLOWED_TAGS: []}).trim()
			if (message) {
				console.log('message = ', message);
				this.$socket
					.invoke("SendMessage", {disclosureId, type: this.activeChat, message})
					.catch(err => console.error(err.toString())) 
			}
			this.message = ''
			this.$refs.message.focus()
		},
		showToast(msg) {
			this.$bvToast.toast(this.truncate(msg.message), {
				title: `New message in ${this.chats[msg.type].text}`,
				toaster: 'b-toaster-bottom-right',
				solid: true,
				appendToast: true
			})
		},
		truncate(str, n = 20) {
			if (str.length <= n) { return str; }
			const subString = str.substr(0, n - 1); // the original check
			return subString.substr(0, subString.lastIndexOf(" ")) + '...'
		}
	},
	sockets: {
		ReceiveMessage(msg) {
			// const message = msg.message.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
			if (!this.$refs.chat) return
			console.log('Receive message 1', msg.type, this.activeChat, msg.type == this.activeChat);
			console.log('Receive message chat = ', this.$refs.chat);
			msg.createdUtc = new Date().toISOString()
			if (msg.type == this.activeChat) {
				// console.log('new message in activeChat');
				this.messagesRaw.push(msg)
				this.scrollWindow()
			} else {
				this.$set(this.unread, msg.type, this.unread[msg.type] + 1)
				// console.log('new message in other chat');
				if (this.allChats[msg.type]) this.allChats[msg.type].push(msg)
				this.showToast(msg)
			}
		}
	}

}
