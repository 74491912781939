import Vue from 'vue'

export default {
	set(state, obj) {
		for (const [key, value] of Object.entries(obj)) {
			// console.log('set', obj);
			Vue.set(state, key, value)

			// state[key] = Object.assign({}, state[key], value)
		}
	},
	setProperty(state, obj) {
		for (const [key, value] of Object.entries(obj)) {
			// console.log('set', obj);
			Vue.set(state, key, {...state[key], ...value})

			// state[key] = Object.assign({}, state[key], value)
		}
	}
}
