<template>
	<b-modal
		id="timeout-modal"
		ref="timeout-modal"
		centered
		hide-header
		hide-footer
		static
		@ok="submit()"
		@cancel="logOut"
		@hide="start"
	>
		<template #default="{ ok, cancel }">
			<div class="mb-3">
				<h2>
					Session timeout warning
				</h2>
				<p>
					You have been inactive for a while. If you don't click continue within {{modalTime}} minutes, you will be automatically logged out and any unsaved changes will be lost.
				</p>
			</div>
			<div class="d-flex align-items-center">
				<b-button
					class="mr-auto"
					variant="outline-primary"
					@click="cancel()"
				>
					Exit
				</b-button>
				<b-button

					@click="ok()"
				>
					Continue 
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
// 3 minutes
import { canLogout } from '@/mixins'
import checkActivity from '@/utils/checkActivity'

const inactivityTime = 4 * 60 * 1000

export default {
	name: 'TimeoutModal',
	mixins: [canLogout],
	data() {
		return {
			modalTimer: 0,
			modalTime: 2,
			active: false
		}
	},
	watch: {
		'$store.state.activityTimer'(val) {
			console.log('starting timer');
			val ? this.start() : this.cancelTimer()
		}
	},
	methods: {
		start() {
			this.cancelTimer()
			this.active = true
			checkActivity(inactivityTime).then(() => {
				// no activity for inactivityTime
				if (this.active) this.show()
			})
		},
		startTimer() {
			// console.log('\nstartTimer called');
			this.cancelTimer()
			this.modalTimer = setTimeout(this.timeout, this.modalTime * 60 * 1000);
		},
		cancelTimer() {
			// console.log('\ncancelTimer called');
			this.active = false
			clearTimeout(this.modalTimer)
		},
		show() {
			this.startTimer()
			console.log('show timeout');
			this.$bvModal.show('timeout-modal')
			// this.$refs['timeout-modal'].show()
		},
		timeout() {
			console.log('timeout');
			this.$bvModal.hide('timeout-modal')
			// this.$refs['timeout-modal'].hide()
			// console.log('TIMEOUT!!!!!');
			this.logOut()
			// store.auth.
		},
		submit() {
			// restart inactivity timer
			this.start()
			this.$bvModal.hide('timeout-modal')
			// this.$refs['timeout-modal'].hide()
		},
		save() {
			this.$store.dispatch('save').then(() => {
				this.logOut()
			})
		}
	}
}
</script>