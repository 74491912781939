<template>
	<div v-if="regional1800" class="d-inline-block">
		<a v-if="regional1800.length == 1" :href="`tel:${selected.number}`" class="mr-4 phone-link"><icon-phone class="mr-3 mt-n1 text-success" /> <span class="call">Call</span> {{selected.number | format1800}}</a>
		<div v-else>
			<a :href="`tel:${selected.number}`" class="mr-4 phone-link"><icon-phone class="mr-3 mt-n1 text-success" /> <span class="call">Call</span> {{selected.number | format1800}}</a>
			<div class="text-center mt-n1">
				<a v-for="(num, i) in regional1800" :key="num.region" href="#" :class="{selected:index == i}" class="region-btn mr-2 small" @click.prevent="index = i">{{num.region}}</a>
			</div>
		</div>
	</div>
</template>

<script>
import { IconPhone } from "./";
export default {
	name: 'Number1800',
	components: {
		IconPhone
	},
	data() {
		return {
			index: 0
		}
	},
	computed: {
		regional1800() {
			return this.$store.state.org.regional1800
		},
		selected() {
			return this.regional1800[this.index] || {}
		}
	},
}
</script>

<style lang="scss">
.call {
	display: var(--home-header-call-display);
}
.phone-link {
	color: inherit;
	&:hover {
		text-decoration: none;
		color: var(--home-header-color-secondary);
		// color: $success;
	}
}
.optus .phone-link {
	font-weight: bold;
}
.region-btn{
	color: var(--home-header-color-secondary);
	&:hover{
		color: var(--home-header-color);
		text-decoration: none;
	}
	&.selected {
		text-decoration: underline;
		color: var(--home-header-color);
	}
}
</style>