<template>
	<svg style="display:none">
		<!-- ICON 1 -->
		<symbol id="icon-1" viewBox="-154 165.9 303 463.1">
			<path
				fill="#FFFFFF"
				d="M-5.6,498.5h-103.6c-1.9,0-3.4,1.5-3.4,3.4l0,0v36.3c0,1.9,1.5,3.4,3.4,3.4l0,0h57.6l-4-10.5l27.5,10.5h22.6
	c1.9,0,3.4-1.5,3.4-3.4l0,0v-36.3C-2.1,500-3.7,498.5-5.6,498.5C-5.5,498.5-5.6,498.5-5.6,498.5z"
			/>
			<polygon
				opacity="var(--info-1-arrow-op)"
				fill="var(--info-1-arrow)"
				enable-background="new    "
				points="-39.8,572.4 -29.4,562.1 -17.3,574.3 -12.3,569.3 
	-24.5,557.2 -14.2,546.9 -28.1,541.5 -51.6,541.5 "
			/>
			<polygon opacity="var(--info-1-arrow-op)" fill="var(--info-1-arrow)" enable-background="new    " points="-51.6,541.5 -28.1,541.5 -55.6,531 " />
			<path
				class="text-1"
				d="M-84.5,515.8c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.3,0.5,0.7,0.7,1.1c0.2,0.4,0.2,0.9,0.2,1.3
	c0,0.6-0.2,1.3-0.5,1.8c-0.3,0.6-0.9,1.1-1.5,1.3c0.5,0.2,1,0.5,1.3,1c0.3,0.6,0.4,1.3,0.4,2v0.9c0,0.4,0,0.8,0.1,1.2
	c0.1,0.3,0.2,0.6,0.5,0.7v0.3h-3c-0.1-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.1-0.7-0.1-1.1v-1.2c0.1-0.6-0.1-1.2-0.4-1.6
	c-0.4-0.3-1-0.5-1.5-0.4H-90v5.1h-2.6v-12.9h6.2C-85.9,515.5-85.2,515.6-84.5,515.8z M-90.1,517.7v3.5h2.9c0.4,0,0.9,0,1.3-0.2
	c0.5-0.3,0.8-0.9,0.8-1.5c0.1-0.6-0.2-1.2-0.7-1.6c-0.4-0.2-0.8-0.2-1.3-0.2H-90.1z"
			/>
			<path class="text-1" d="M-70.3,517.8h-6.8v2.7h6.3v2.3h-6.3v3.3h7.2v2.3h-9.8v-12.9h9.5L-70.3,517.8L-70.3,517.8z" />
			<path
				class="text-1"
				d="M-59.2,522.8c-0.9,0.7-2.1,1-3.2,1h-2.7v4.6h-2.7v-12.9h5.5c1.1-0.1,2.2,0.3,3.1,1c0.8,0.8,1.2,1.9,1.1,3.1
	C-57.9,520.8-58.4,522-59.2,522.8z M-61.3,518.2c-0.4-0.3-0.9-0.5-1.4-0.4h-2.4v3.8h2.4c0.5,0,1-0.1,1.4-0.5s0.6-0.9,0.5-1.5
	C-60.7,519.1-60.9,518.5-61.3,518.2z"
			/>
			<path
				class="text-1"
				d="M-45.9,527.3c-2.5,2-6,2-8.5,0c-1.4-1.4-2.1-3.4-2-5.3c-0.1-2,0.6-3.9,2-5.3c2.5-2,6-2,8.5,0
	c1.4,1.4,2.1,3.3,2,5.3C-43.8,523.9-44.6,525.9-45.9,527.3z M-47.6,525.3c1.3-2.1,1.3-4.7,0-6.7c-0.6-0.8-1.6-1.2-2.5-1.2
	c-1,0-1.9,0.4-2.6,1.2c-1.3,2.1-1.3,4.7,0,6.7c0.6,0.8,1.6,1.2,2.6,1.2C-49.2,526.5-48.3,526.1-47.6,525.3L-47.6,525.3z"
			/>
			<path
				class="text-1"
				d="M-33.6,515.8c0.5,0.2,0.9,0.5,1.2,0.9c0.3,0.3,0.5,0.7,0.6,1.1c0.2,0.4,0.2,0.9,0.2,1.3
	c0,0.6-0.2,1.3-0.5,1.8c-0.3,0.6-0.9,1.1-1.5,1.3c0.5,0.2,1,0.5,1.3,1c0.3,0.6,0.4,1.3,0.4,2v0.9c0,0.4,0,0.8,0.1,1.2
	c0.1,0.3,0.2,0.6,0.5,0.7v0.3h-3c-0.1-0.3-0.1-0.5-0.2-0.7c-0.1-0.4-0.1-0.7-0.1-1.1v-1.2c0.1-0.6-0.1-1.2-0.4-1.7
	c-0.4-0.3-1-0.5-1.5-0.4h-2.6v5.1h-2.6v-12.9h6.2C-35,515.5-34.3,515.6-33.6,515.8z M-39.2,517.7v3.5h2.9c0.4,0,0.9,0,1.3-0.2
	c0.5-0.3,0.8-0.9,0.8-1.5c0.1-0.6-0.2-1.2-0.7-1.6c-0.4-0.2-0.8-0.2-1.3-0.2H-39.2z"
			/>
			<path class="text-1" d="M-19.6,515.5v2.3h-3.9v10.6h-2.7v-10.6h-3.9v-2.3H-19.6z" />
		</symbol>

		<!-- icon 2 -->
		<symbol id="icon-2" viewBox="-154 165.8 303 462.2">
			<path
				fill="var(--info-2-bg-1)"
				d="M-52.7,219.3h-37.1c-8.5,0-15.5,6.9-15.5,15.5v21.6c0,7.1,4.9,13.4,11.9,15l-3.1,14.1l21.4-13.6h22.3
			c8.5,0,15.5-6.9,15.5-15.5v-21.7C-37.3,226.3-44.2,219.3-52.7,219.3"
			/>
			<path
				fill="var(--info-2-bg-3)"
				d="M-63.8,287.6h-22.7c-5.2,0-9.4,4.2-9.4,9.4l0,0v13.2c0,4.4,3,8.2,7.3,9.2l-1.8,8.6l13.1-8.3h13.6
			c5.2,0,9.4-4.2,9.4-9.4v-13.2C-54.4,291.8-58.6,287.6-63.8,287.6"
			/>
			<path
				fill="var(--info-2-bg-2)"
				d="M-3.8,296.1c6.7-5.2,10.7-13.2,10.7-21.7c0-15.8-13.9-28.7-30.9-28.7s-30.9,12.8-30.9,28.7
			c0,15.8,13.8,28.7,30.9,28.7c4,0,8.1-0.7,11.8-2.2l13.1,7L-3.8,296.1z"
			/>
			<rect x="-91.3" y="239.6" fill="#FFFFFF" width="40.2" height="5.1" />
			<rect x="-91.3" y="249.6" fill="#FFFFFF" width="34.6" height="5.1" />
			<rect x="-40.5" y="268.2" fill="var(--info-2-lines-2)" width="35.2" height="5.5" />
			<rect x="-40.5" y="279.8" fill="var(--info-2-lines-2)" width="28.4" height="5.5" />
		</symbol>

		<!-- icon 3 -->
		<symbol id="icon-3" viewBox="-154 165 303 463">
			<path
				fill="#FFFFFF"
				d="M-25.9,505.9h-32.9c-8.3,0-14.9,6.7-14.9,14.9l0,0v20.9c0,8.3,6.7,14.9,14.9,14.9l0,0h10.3l21.2,11.9
			l-2.4-11.9h3.9c8.2,0,14.9-6.7,14.9-14.9l0,0v-20.9C-10.9,512.6-17.6,505.9-25.9,505.9C-25.8,505.9-25.8,505.9-25.9,505.9"
			/>
			<path
				opacity="var(--info-3-bg-1-op)"
				fill="var(--info-3-bg-1)"
				enable-background="new    "
				d="M-48,488.7h-38.6c-8.9,0-16.1,7.2-16.1,16.1l0,0v22.5
			c0,7.4,5.1,13.9,12.4,15.6l-4.3,19.8l23.5-19.3h23.2c8.9,0,16.1-7.2,16.1-16.1v-22.5C-31.9,495.9-39.1,488.7-48,488.7L-48,488.7"
			/>
			<path
				fill="#FFFFFF"
				d="M-76.9,516c0,2.7-2.2,4.8-4.8,4.8c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8l0,0
			C-79.1,511.2-76.9,513.4-76.9,516"
			/>
			<path
				fill="#FFFFFF"
				d="M-62.4,516c0,2.7-2.2,4.8-4.8,4.8s-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8l0,0
			C-64.6,511.2-62.5,513.4-62.4,516"
			/>
			<path
				fill="#FFFFFF"
				d="M-48,516c0,2.7-2.2,4.8-4.8,4.8c-2.7,0-4.8-2.2-4.8-4.8c0-2.7,2.2-4.8,4.8-4.8l0,0
			C-50.1,511.2-48,513.3-48,516L-48,516"
			/>
		</symbol>

		<!-- icon 4 -->
		<symbol id="icon-4" viewBox="-154 165.8 303 462.2">
			<path
				fill="#FFFFFF"
				d="M-67.1,281.1L-67.1,281.1L-67.1,281.1c-0.3,0.3-0.6,0.6-0.8,0.9l-32-3.5c0-0.2,0-0.4,0-0.6
		c0-1,0.4-2,1.1-2.8c1.5-1.6,4.3-2.1,6.9-1.2l25.3,6.7L-67.1,281.1z"
			/>
			<path
				fill="#FFFFFF"
				d="M-69.7,285.8c0,0.1,0,0.2,0,0.3s0,0.1,0,0.1c0,0.2-0.1,0.5-0.1,0.7l0,0l-25.6,1c-3.3,0-6.3,1.7-7.9,4.6
		c-1.3-0.4-2.6-0.8-3.8-1.2c-2.2-0.5-3.8-2.4-3.9-4.7c0-0.8,0.3-1.6,0.7-2.3c1.3-1.8,3.9-2.7,6.6-2.3L-69.7,285.8z"
			/>
			<path
				fill="#FFFFFF"
				d="M-55.7,306.8c-1.7,1-3.8,1.4-5.8,1.1c-7.3-1.2-25.5-4.2-35.5-6.5c-2.1-0.5-3.5-2.5-3.5-4.6
		c0-0.6,0.1-1.2,0.3-1.8c0.7-1.9,2.6-3.2,4.7-3.1l26.2-1c0.5,1.2,1.2,2.3,2.1,3.2c3.2,3.2,8,3.5,12.7,0.6l0.1-0.1l0.1-0.1l13-9
		l-2.2-3.3l-13,9c-1.5,0.9-5.2,2.6-7.9,0c-0.7-0.7-1.2-1.6-1.4-2.6c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.4V288c0-0.1,0-0.1,0-0.2v-0.2
		c0-1.4,0.5-2.7,1.5-3.7l22.3-21.7c1.8-1.7,4.2-2.6,6.7-2.4l13.4,1l19.4-0.4v28.5l-23.7,0.3L-55.7,306.8z"
			/>
			<path
				fill="var(--info-4-bg-1)"
				d="M-45,246.5c0-0.1,0-0.1,0-0.2c-0.1-6.5-5.5-11.7-12-11.7l0,0c-3,0-5.9,1.1-8.1,3.1
		c-0.6,0.6-1.2,1.2-1.7,1.9c-0.5-0.7-1.1-1.3-1.7-1.9c-2.2-2-5-3.1-8-3.1c-6.5-0.1-11.9,5.1-12,11.6l0,0c0,0.1,0,0.1,0,0.2l0,0
		c-0.1,3.8,1.5,7.9,4.7,11.8c3.6,4.4,9,8.4,16.1,12l1,0.5l1-0.5c7.1-3.6,12.6-7.6,16.1-12C-46.5,254.4-44.9,250.3-45,246.5
		L-45,246.5z"
			/>
			<path
				fill="#FFFFFF"
				d="M-76.4,242.6c0,1.6-1.3,2.9-2.9,2.9c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9l0,0
		C-77.7,239.7-76.4,241-76.4,242.6L-76.4,242.6z"
			/>
		</symbol>
	</svg>
</template>
<script>
export default {
	name: 'InfoGraphicIcons'
}
</script>