import {sanitize} from 'dompurify';
export default {
	methods: {
		sanitize,
		fix(prop, i) {
			console.log('fix', prop, this[prop]);
			if (i !== undefined) this.$set(this[prop], i, sanitize(this[prop][i]))
			else this[prop] = sanitize(this[prop])
		}
	}	
}