import { sanitize } from "dompurify"
export default {
	data() {
		return {
			questions: [],
			questionGroup: '',
			isLoaded: false,
			isSubmitted: false // only show errors after submit
		}
	},
	created () {
		this.loadQuestions()
		this.markPrevStepVisited()
	},
	computed: {
		isValid() {
			// return this.questions.length
			return !this.questions.some(q => !q.isValid)
			// Array.some()
		}
	},
	methods: {
		loadQuestions() {
			const action = this.questionGroup == 'details' ? 'loadDetails' : 'loadQuestions'
			this.$store.dispatch('questions/' + action, this.questionGroup).then(response => {
				this.$set(this, 'questions', response)
				// this.questions = response
				// this.validate()
				this.isLoaded = true
			})
		},
		// maybeValidate(q, i) {
		// 	console.log('maybeValidate', q, i);
		// 	if (this.isSubmitted) this.validate(q, i)
		// },
		submit(e) {
			// console.log('submit', this.$store.state.qBackground);
			this.validateAll()
			this.isSubmitted = true
			if (this.isValid) {
				this.$router.push('/' + this.nextStep)
			} else {
				const firstInvalid = this.questions.find(q => !q.isValid)
				const el = document.getElementById('q' + firstInvalid.questionId)
				const ta = el.querySelector('textarea')
				if (ta) {
					ta.focus()
					ta.select()
				}
				el.scrollIntoView({behavior: 'smooth', block: 'center'})
			}
		},
		markPrevStepVisited () {
			// console.log('markStepVisited', this.$route.path);
			if (this.prevStep) this.$store.commit('steps/markAsVisited', '/' + this.prevStep)
		},
		validateAll() {
			this.questions.forEach((q, i) => {
				this.validate(q, i, true)
			})
		},
		validate(q, i, fix) {
			console.log('validate', q);
			if (fix && q.questionType == 2) q.response = sanitize(q.response, {ALLOWED_TAGS: []})
			q.isValid = !(q.isMandatory && (!q.response || !q.response.length))
			this.$set(this.questions, i, q)
		}
	},
}