<template>
	<div>
		<div class="left-col transition-all h-100" :class="{
		'bg-primary': bgPrimary, 
		'd-inline-block d-md-block': !$store.state.routeMeta.portal,
		'd-none d-xl-block': $store.state.routeMeta.portal}">
			<div class="pl-3 pl-sm-4 align-self-start sticky-top ">
				<Logo class="my-3 my-md-4" />
				<transition name="fade">
					<submit-progress v-if="$route.meta.showMenu" class="pt-6 pt-sm-7 d-none d-md-flex flex-column delayed" />
					<!-- <submit-progress v-if="$store.state.routeMeta.showMenu" class="pt-6 pt-sm-7 d-none d-md-flex flex-column " /> -->
				</transition>
			</div>
		</div>
		<Logo class="px-main my-3 my-md-4" :class="$store.state.routeMeta.portal ? 'd-block d-xl-none' : 'd-none'" />
	</div>
</template>

<script>
export default {
	name: 'LeftCol',
	// data() {
	// 	return {
	// 		bgPrimary: false
	// 	}
	// },
	computed: {
		bgPrimary() {
			return this.$store.state.routeMeta.LeftColBlue && this.$route.meta.LeftColBlue
		},
	}
	// watch: {
	// 	'$store.state.routeMeta.LeftColBlue' ()
	// }
}
</script>
 
<style lang="scss" scoped>

.left-col {
	animation: fade-in $transition-duration 1 forwards;
	@include media-breakpoint-up(md) {
		min-width: $left-col-w;
		background-color: white;
	}
}
svg{
	color: $primary;
	cursor: pointer;
	&:hover{
		color: #000;
	}
	position: absolute;
	@include media-breakpoint-down(sm) {
		position: static;
	}
}
.bg-primary svg{
	color: #ffffff;
	&:hover{
		color: #cccccc;
	}
}
</style>
