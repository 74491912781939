const INITIAL_STATE = {
	active: false,	
	title: '',
	body: '',
	resolve: null,
	reject: null,
	timer: null,
	isUnsafeFile: false,
	hasCancel: true
}

const state = {...INITIAL_STATE}

const mutations = {
	activate: (state, payload) => {
		// console.log(confirmer activate! , payload);
		state = Object.assign(state, payload, {active: true})
	},
	deactivate: (state) => {
		// console.log('confirmer deactivate!!!!!! ', payload);
		if (state.timer)clearTimeout(state.timer)
		state = {...INITIAL_STATE}
	},
}
	
const actions = {
	ask: ({ commit, dispatch }, { title, body, timeout, saveFooter = false, saveLabel = 'OK', hasCancel = true, isUnsafeFile = false }) => {
		return new Promise((resolve, reject) => {
			if (timeout) dispatch('startTimer', {timeout, func: reject})
			console.log('confirmer ask timeout', timeout, resolve, reject);
			
			commit('activate', {
				title,
				body,
				saveLabel,
				saveFooter,
				isUnsafeFile,
				hasCancel,
				resolve,
				reject
			})
		})
	},

	startTimer: ({commit}, {timeout, func}) => {
		console.log('startTimer', timeout, func);
		
		const timer = setTimeout(func, timeout);
		commit('setTimer', timer)
	}
}
	
export default {
	namespaced: true,
	state,
	mutations,
	actions,
}