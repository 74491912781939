<template>
	<b-form-select v-if="$store.state.saved" v-model="selected" :options="options" size="sm" class="w-auto" @change="selectChanged" />
</template>

<script>
export default {
	data() {
		return {
			selected: 'review',
			options: [
				{ value: 'review', text: 'My Report' },
				{ value: 'my-account', text: 'Account Details' },
				{ value: 0, text: 'Log Out' },
			]
		}
	},
	methods: {
		selectChanged(e) {
			if (e) {
				this.$router.push(`/${e}`)
			} else {
				this.logOut()
			}
		},
		logOut() {
			this.$router.push('/')
			setTimeout(() => {
				window.location.reload(false)
			}, 350);
		}
	}
}
</script>