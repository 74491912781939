<template>
	<div class="page-header pt-3 pt-md-4 px-main r-0 ml-auto">
		<div class="d-none d-md-block">
			Having troubles? 
			<router-link to="/help">Get Help</router-link>
			<user-menu v-if="$store.state.login.isLoggedIn" />
			<!-- <select-logged-in v-if="$store.state.saved" /> -->

			<!-- <b-form-select v-if="$store.state.saved" v-model="selected" :options="options" size="sm" class="w-auto ml-3" @change="selectChanged" /> -->
		</div>
		<mobile-menu class="d-md-none text-primary" />
	</div>
</template> 

<script>
import UserMenu from './ui/UserMenu.vue'
export default {
	name: 'Header',
	components: { UserMenu },
	// methods: {
	// 	logOut() {
	// 		this.$router.push('/')
	// 		setTimeout(() => {
	// 			window.location.reload()
	// 		}, 350);
	// 	}
	// }
}
</script>

<style lang="scss">
.page-header {
	position: absolute;
	z-index: 200;
	// transition: background-color $transition-duration;
	// // @include media-breakpoint-up(md){
	// // 	position: fixed;
	// // 	z-index: 100;
	// // }
	// @include media-breakpoint-down(sm){
	// 	background: none !important;
	// }
	select{
		background-color: #EAEEF6;
	}
}
</style>