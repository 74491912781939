<template>
	<div class="loaded">
		<!-- DESKTOP -->
		<b-container class="content intro" fluid>
			<b-row class="maw-lg">
				<b-col xl="3" class="d-none d-xl-block order-2">
					<client-logo square class="float-right" />
				</b-col>
				<b-col xl="9" class="mx-auto">
					<h1 class="mb-3">Make your Report</h1>
					<client-logo class="d-xl-none mb-3 p-0" />
					<h4 class="mb-4">You are making a report through the<strong><template v-if="org.code != 'DFLT'"> {{orgName | capitalise}}</template> {{term}}</strong> service, managed by PKF Integrity. Please provide as much information as you can so that the matter can be reviewed in detail.</h4>
					<submit-progress class="d-flex d-md-none maw-sm mx-auto" />
					<report-id v-if="$store.state.saved" class="large mt-4" white />
				</b-col>
			</b-row>
		</b-container>
		<b-container class="content body" fluid>
			<b-row>
				<b-col>
					<transition name="fade" mode="out-in" @enter="$root.$emit('triggerScroll')">
						<router-view :key="$route.name" />
					</transition>
					<div class="spinner abs mt-4"></div>
				</b-col>
			</b-row>
		</b-container>
		<!-- MOBILE -->
		<!-- <div>
		</div>
		<div class="pr-5 pt-7 bg-secondary flex-grow-1"></div> -->
	</div>
</template>

<script>
import { SubmitProgress } from '@/components/ui'
export default {
	name: 'SubmitReportUI',
	components: { SubmitProgress },
	computed: {
		org() {
			return this.$store.state.org
		},
	}
}
</script>