<template>
	<div class="bg-swirl home-banner">
		<b-container class="pb-5 pb-md-7 pt-5 pt-md-6 px-main">
			<b-row>
				<b-col lg="7" class="text-white">
					<h1>PKF Optus whistleblower portal</h1>
					<p class="sub-head">
						This portal is serviced by PKF Integrity on behalf of Optus. Disclosure reports made to this service are confidential, and your privacy is guaranteed.
					</p>
					<b-button to="/step1" size="lg" class="btn-submit-report d-none d-lg-inline-flex align-items-center p-3 pr-35 mr-3 mt-3 mb-4"><icon-secure width="24" height="24" class="mr-3 mt-n1" /> Submit Report</b-button>
					<!-- <div class="d-flex flex-column flex-sm-row mb-4">
						<client-logo square class="align-self-center" />
						<ul class="ticks d-flex flex-column">
							<li>
								<span>All matters are received by an impartial and independent third party</span>
							</li>
							<li>
								<span>Disclosures are reviewed by a whistleblowing expert, with a background in investigations</span>
							</li>
							<li>
								<span>Your identity is 100% protected</span>
							</li>
						</ul>
					</div> -->
					<h3 class="text-white">Make your report with confidence with PKF</h3>
					<p>PKF Integrity Services is independent and has significant expertise in handling whistleblowers. If you have a concern or a matter you would like to report, you can do so here, knowing that it will be handled in the strictest of confidence and in a highly professional manner.</p>
					<p><a href="#ticks" class="arrow text-white">Learn more about PKF</a></p>
				</b-col>
				<b-col lg="4" offset-lg="1" class="d-md-flex mt-4 mt-md-0 justify-content-end align-items-start">
					<div class="lodge-info text-white">
						<h2>Three ways to lodge a disclosure with us</h2>
						<router-link to="/step1" class="d-flex align-items-center mt-3"> <img src="/img/icon-file.svg" class="mr-2 mt-n1" />Submit a <span class="link">report online</span></router-link>
						<a class="d-flex align-items-center mt-3" :href="'tel:' + $store.state.org.number1800">
							<IconPhone class="mr-2 mt-n1" />Call us on <span class="link">{{ $store.state.org.number1800 | format1800 }}</span>
						</a>
						<a class="d-flex align-items-center mt-3" :href="'mailto:' + $store.state.org.email">
							<b-icon-envelope-fill class="mr-2 mt-n1" />Email <span class="d-none d-sm-inline-block">&nbsp;us on</span> <span class="link">{{ $store.state.org.email }}</span>
						</a>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import IconPhone from './IconPhone'
export default {
	components: { IconPhone },
}
</script>

<style lang="scss">
.home-banner {
	h1 {
		margin-bottom: 1.5rem;
	}
}
.sub-head {
	color: #fff !important;
	margin-bottom: 1.5rem;
}
ul.ticks {
	margin: 1.2rem 0;
	justify-content: space-evenly;
	li {
		padding-left: 50px;
		position: relative;
		list-style: none;
		&::before {
			content: '';
			position: absolute;
			top: -4px;
			left: 0;
			width: 36px;
			height: 35px;
			background: url(/img/tick.svg) no-repeat;
			background-size: cover;
		}
		span {
			opacity: 0.8;
			font-size: 20px;
			line-height: 1.2em;
		}
	}
	@include media-breakpoint-down(xs) {
		padding-left: 0;
		margin-top: 50px;
		margin-bottom: 0;
	}
}
.lodge-info {
	margin: 0 auto;
	max-width: 450px;
	position: relative;
	a {
		color: #fff;
		font-weight: bold;
		font-size: 0.9rem;
		white-space: nowrap;
		padding: 0.5rem 0.8rem;
		background-color: rgba(255, 255, 255, 0.3);
		border-radius: 4px;
		span {
			white-space: pre;
		}
		.link {
			color: $optus-gold;
			margin: 0 0.4em;
			text-decoration: underline;
		}
		svg {
			width: 15px;
		}
		&:hover {
			background-color: rgba(255, 255, 255, 0.15);
			.link {
				text-decoration: none;
			}
		}
	}
	@include media-breakpoint-only(md) {
		top: 60px;
	}
	@include media-breakpoint-up(sm) {
		padding: 2rem;
		border: 1px solid white;
		&:before {
			position: absolute;
			content: '';
			bottom: 100%;
			right: -31px;
			height: 31px;
			width: 206px;
			background-color: $optus-gold;
		}
		&:after {
			position: absolute;
			content: '';
			top: -31px;
			right: -31px;
			height: 149px;
			width: 31px;
			background-color: $optus-gold;
		}
	}
}
</style>
