export default {
	computed: {
		email: {
			get() {
				return this.$store.state.login.email
			},
			set(email) {
				if (this.validateEmail(email)) this.$store.commit('login/set', {email})
			}
		},
	},
	methods: {
		validateEmail(email) {
			var re = /\S+@\S+\.\S+/;
			return re.test(email);
		}
	},
}