// import unsafeWarning from "@/utils/unsafeWarning"

export default {
	data() {
		return {
			uploading: false,
			newFiles: []
		}
	},
	computed: {
		files: {
			get() {
				return this.report.additionalDocuments || []
			},
			set(val) {
				this.$store.commit('addDocs', val)
			}
		},
		report() {
			return this.$store.state.report
		}

	},
	methods: {
		async checkFiles(files) {
			if (!files.length) return
			const unsafe = files.filter(f => !this.isSafe(f.name))
			if (unsafe.length) {
				const sure = await this.$store.dispatch('confirmer/ask', {title: 'WARNING!', isUnsafeFile: true, saveLabel: 'Proceed'})
				if (sure) this.upload(files)
			} else {
				this.upload(files)
			}
			console.log('this.unsafe', unsafe);
		},
		isSafe(filename) {
			const safe = ['pdf', 'xlsx', 'docx', 'pptx']
			const dot = filename.lastIndexOf('.') + 1
			const ext = filename.slice(dot).toLowerCase()
			return safe.includes(ext)
		},
		async upload(arr) {
			console.log('uploadA! e ', arr);
			this.newFiles = []
			const files = arr.map((file, index) => ({
				file,
				index,
				state: 'Queued',
				fileName: file.name,
				progress: 0,
			}))
			this.files = this.files.concat(files)
			for (const f of files) {
				this.uploadFile(f)
			}
			console.log('uploadB! files, this.files, length ', files, this.files, this.files.length);
		},
		async uploadFile(f) {
			f.state = 'Uploading'
			const formData = new FormData();
			formData.append("file", f.file);
			if (this.$store.state.disclosureId) {
				formData.append('disclosureId', this.$store.state.disclosureId)
			} else {
				let guid = this.$store.state.guid
				if (!guid) guid = await this.$store.dispatch('createGUID')
				formData.append('tempFolder', guid)
			}
			this.$http.post(this.$store.getters.apiPath + 'docs', formData, {
				headers: {'Content-Type': 'multipart/form-data', Cookie: ''},
				onUploadProgress: e => {
					console.log('upload progress', e)
					this.$set(f, 'progress', (e.loaded * 100) / e.total)
				}
			}).then(response => {
				const {fileId, fileName} = response.data
				f.fileId = fileId
				f.fileName = fileName
				// f.state = null
				this.markAsComplete(f)
			}).catch(e => {
				this.markAsProblem(f)
			})
			// console.log('uploadFile')
			// return new Promise(resolve => setTimeout(resolve, 2000))
		},
		// onProgress(e, f) {
			
		// },
		downloadFile(f) {
			// Maybe this, maybe just a href in _blank
			window.open(this.$store.getters.apiDisclosurePath + 'docs/' + f.fileId)
		},
		markAsComplete(f) {
			console.log('mark', f)
			f.state = 'Complete'
			setTimeout(() => {
				f.state = null
			}, 800);
		},
		markAsProblem(f) {
			f.state = 'There was a problem uploading this file.'
			setTimeout(() => {
				this.deleteFile(f.index)
			}, 4000);
		},
		deleteFile(index) {
			const id = this.files[index].fileId
			const o = {tempFolder: this.$store.state.guid}
			this.$http.delete(`${this.$store.getters.apiPath}docs/${id}`, {data: o})
			this.files.splice(index, 1)
		}
	}
}
