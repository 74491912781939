<template>
	<div class="user-menu ml-3">
		<icon-user />
		<nav class="pt-2 pb-3 px-3 mr-n3 text-right">
			<div class="py-2 bg-pink">
				<a v-for="item in options" :key="item.value" href="#" class="nav-item px-3 py-1" @click.prevent="navClicked(item.value)">{{item.text}}</a>
			</div>
		</nav>
	</div>
</template>

<script>
import { IconUser } from "./"
import { canLogout } from '@/mixins'

export default {
	name: 'UserMenu',
	components: {
		IconUser
	},
	mixins: [canLogout],
	data() {
		return {
			options: [
				{ value: 'my-portal', text: 'My Report' },
				{ value: 'my-account', text: 'Account Details' },
				{ value: 0, text: 'Log Out' },
			]
		}
	},
	methods: {
		navClicked(e) {
			if (e) {
				this.$router.push(`/${e}`)
			} else {
				this.logOut()
			}
		},
	}
}
</script>

<style lang="scss">
.user-menu {
	position: relative;
	display: inline-block;
	nav {
		position: absolute;
		top: 100%;
		right: -1rem;
		width: 200px;
		pointer-events: none;
		opacity: 0;
		transition: all $transition-fast;
		transform: translateY(-20px);
		a {
			display: block;
		}
	}
	&:hover{
		nav {
			transform: none;
			pointer-events: all;
			opacity: 1;
		}
	}
}

</style>