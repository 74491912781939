import axios from 'axios'
import { sanitize } from 'dompurify';
import setState from "../common/setState";

const state = () => ({
	account: '',
	email: null,
	password: '',
	state: 'save',
	name: '',
	mobile: null,
	anon: false,
	isLoggedIn: false
})

const mutations = {
	...setState,
	reset(state) {
		state.email = ''
		state.name = ''
		state.password = ''
		state.mobile = ''
		state.isLoggedIn = false
	},
	clean(state) {
		state.name = sanitize(state.name, {ALLOWED_TAGS: []})
		state.email = sanitize(state.email, {ALLOWED_TAGS: []})
		state.mobile = sanitize(state.mobile, {ALLOWED_TAGS: []})
	}
}

const actions = {
	login({state, commit, dispatch, rootGetters, rootState}, {password, disclosureId}) {
		commit('set', {password})
		// console.log("login/login", {disclosureId: reportID, password});
		return axios
			.post(rootGetters.apiBasePath + 'auth/login/discloser', {disclosureId, password})
			.then(async response => {
			// testing only
			// this will come from api
			// {
			// 	"isDisclosureSubmitted": true,
			// 	"expiration": "2021-02-22T01:01:21.933Z",
			// 	"orgCode": "string",
			// 	"hasAdditionalQuestion": true
			//  }

				window.sessionStorage.setItem('disclosureId', disclosureId)
				window.sessionStorage.setItem('expiry', response.data.expirationUtc)
				window.sessionStorage.setItem('orgCode', response.data.orgCode)
				window.sessionStorage.setItem('serviceOption', response.data.serviceOption)
				window.sessionStorage.setItem('isDisclosureSubmitted', response.data.isDisclosureSubmitted)
				await dispatch('isLoggedIn', {...response.data, disclosureId})
				// this stuff will stay
				return response
			})
	}, 
	async isLoggedIn({dispatch, commit, rootState}, o) {
		const d = new Date(o.expirationUtc)
		dispatch('startRefreshTimer', d)
		commit('set', {saved: true, disclosureId: o.disclosureId, activityTimer: true}, {root: true})
		if (o.isDisclosureSubmitted) commit('set', {isLoggedIn: true})
		console.log('login', o);
		if (rootState.org.code != o.orgCode) await dispatch('init', o.orgCode, {root: true})
		commit('setServiceOption', o.serviceOption, {root: true})
		return true
	},
	logout({commit, rootGetters}) {
		window.sessionStorage.removeItem('expiry')
		commit('reset')
		commit('questions/reset', null, {root: true})
		commit('steps/reset', null, {root: true})
		commit('set', {saved: false, activityTimer: false, isRetrieving: false, report: {}}, {root: true})
		axios.delete(rootGetters.apiBasePath + 'auth/logout')
		return Promise.resolve()
	},
	startRefreshTimer({dispatch}, endDate) {
		const now = new Date()
		let diff = endDate.getTime() - now.getTime() - 100000// allow 100s for retrieval of refresh token
		// this should be temporary, it's been coming through in the past from api
		diff = Math.min(diff, 58 * 60 * 1000) // minimum refresh time 58 minutes
		// console.log('start refresh timer diff', diff);
		setTimeout(() => {
			dispatch('refresh')
		}, diff)
	},
	refresh({dispatch, rootGetters}) {
		axios.get(rootGetters.apiBasePath + 'auth/refresh').then(response => {
			window.sessionStorage.setItem('expiry', response.data.expirationUtc)
			dispatch('startRefreshTimer', new Date(response.data))
		})
		// console.log('refresh');
	},
	loadDetails({state, commit, rootGetters}) {
		return new Promise(resolve => {
			// console.log('login load', state)
			if (state.email && state.mobile) return resolve()
			axios.get(rootGetters.apiDisclosurePath + 'account-details').then(response => {
				const {email, mobile} = response.data
				if (!state.mobile) commit('set', {mobile})
				if (!state.email) commit('set', {email})
				resolve()
			})
		})
	}
}
const getters = {
	now: (state) => ({ account: state.account, name: state.name, date: new Date().toJSON() }),
	loginDetails: (state) => ({ Account: state.account, Password: state.password })
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}