<template>
	<div>
		<info-graphic-icons />
<svg v-if="num==1" version="1.1" class="infographic d-sm-none d-lg-block w-100" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="-154 165.9 303 463.1" enable-background="new -154 165.9 303 463.1" xml:space="preserve">
<path fill="var(--info-bg-1)" opacity=".8" enable-background="new    " d="M128.1,186.9c-13.4-13.4-31.6-21-50.6-20.9h-230
	c-0.8,0-1.5,0.7-1.5,1.5v357.1c0,57.7,44.8,104.4,100,104.4S46,582.3,46,524.6S1.2,420.2-54,420.2h-97V168.9H77.5
	c37.8,0,68.5,30.7,68.5,68.5l0,0v390h3v-390C149.1,218.5,141.5,200.2,128.1,186.9z" />
	<use href="#icon-1" transform="matrix(1, 0, 0, 1, -154, 165)" />
<text transform="matrix(1 0 0 1 -109.8531 221.8514)"><tspan x="0" y="0" class="bold" font-size="18px">SUBMIT REPORT OR CALL</tspan><tspan x="0" y="21.6" class="bold" font-size="18px">THE NUMBER ABOVE</tspan></text>
<text transform="matrix(1 0 0 1 -109.8531 276.5662)"><tspan x="0" y="0">Whether you report online or </tspan><tspan x="0" y="19.2">call, the process is safe and </tspan><tspan x="0" y="38.4">simple. You are guided through </tspan><tspan x="0" y="57.6">a short set of questions to gather </tspan><tspan x="0" y="76.8">as much information as possible.</tspan><tspan v-if="$store.state.org.email" x="0" y="96">You can also email PKF directly</tspan><tspan v-if="$store.state.org.email" x="0" y="115.2">at <a :href="'mailto:'+$store.state.org.email">{{$store.state.org.email}}</a></tspan></text>
<g>
	<text transform="matrix(1 0 0 1 -129.7864 221.8511)" class="bold" font-size="18px">1.</text>
</g>
</svg>


<svg v-else-if="num==2" class="infographic d-sm-none d-lg-block w-100" x="0px" y="0px" viewBox="-154 165.8 303 462.2" enable-background="new -154 165.8 303 462.2" xml:space="preserve">
<g>
	<g>
		<path opacity="0.8" fill="var(--info-bg-2)" enable-background="new    " d="M146,165.8V625H-82.5c-37.8,0-68.5-30.7-68.5-68.5V368.2h97
			c55.2,0,100-44.9,100-100.4S1.2,167.4-54,167.4s-100,44.9-100,100.4v288.7c0,39.5,32,71.5,71.5,71.5l0,0h230
			c0.8,0,1.5-0.7,1.5-1.5l0,0V165.8H146z" />
		<use href="#icon-2" transform="matrix(1 0 0 1 -154 166)" />
	</g>
</g>
<text transform="matrix(1 0 0 1 -107.9532 423.4221)"><tspan x="0" y="0" class="bold" font-size="18px">TELL US AS MUCH AS </tspan><tspan x="0" y="21.6" class="bold" font-size="18px">YOU CAN</tspan></text>
<rect x="-108" y="467.2" fill="none" width="234.6" height="142.7" />
<text transform="matrix(1 0 0 1 -107.9532 478.7368)"><tspan x="0" y="0">The more detail you can</tspan><tspan x="0" y="19.2">provide, the better. Specifics </tspan><tspan x="0" y="38.4">such as events, dates, names </tspan><tspan x="0" y="57.6">and locations all make it easier </tspan><tspan x="0" y="76.8">to look into the matter properly.</tspan><tspan x="0" y="96">You always have the option to </tspan><tspan x="0" y="115.2">remain anonymous.</tspan></text>
<g>
	<text transform="matrix(1 0 0 1 -127.8865 423.4217)" class="bold" font-size="18px">2.</text>
</g>
</svg>

<svg v-else-if="num==3" class="infographic d-sm-none d-lg-block w-100" x="0px" y="0px" viewBox="-154 165 303 463" enable-background="new -154 165 303 463" xml:space="preserve">
<g>
	<g>
		<path opacity="0.8" fill="var(--info-bg-1)" enable-background="new    " d="M128.1,185.9c-13.4-13.5-31.6-21-50.6-20.9h-230
			c-0.8,0-1.5,0.7-1.5,1.5v357.2c0,57.6,44.8,104.4,100,104.4S46,581.4,46,523.7S1.2,419.2-54,419.2h-97V168H77.5
			c37.8,0,68.5,30.7,68.5,68.5l0,0v390h3v-390C149.1,217.5,141.5,199.3,128.1,185.9z" />
		<use href="#icon-3" transform="matrix(1, 0, 0, 1, -154, 163)" />
	</g>
</g>
<text transform="matrix(1 0 0 1 -107.5532 220.8025)" class="bold" font-size="18px">STAY IN TOUCH</text>
<rect x="-107.6" y="245.6" fill="none" width="234.6" height="158.2" />
<text transform="matrix(1 0 0 1 -107.5531 257.1163)"><tspan x="0" y="0">You are issued with a unique </tspan><tspan x="0" y="19.2">Report Number. You can also </tspan><tspan x="0" y="38.4">select a password. The </tspan><tspan x="0" y="57.6">password is optional but if you </tspan><tspan x="0" y="76.8">want to check on the status of </tspan><tspan x="0" y="96">your report or provide further </tspan><tspan x="0" y="115.2">evidence later, you will need </tspan><tspan x="0" y="134.4">one.</tspan></text>
<g>
	<text transform="matrix(1 0 0 1 -127.4864 220.8022)" class="bold" font-size="18px">3.</text>
</g>
</svg>

<svg v-else class="infographic d-sm-none d-lg-block w-100" x="0px" y="0px" viewBox="-154 165.8 303 462.2" enable-background="new -154 165.8 303 462.2" xml:space="preserve">
<g>
	<path opacity="0.8" fill="var(--info-bg-2)" enable-background="new    " d="M146,165.8v459.2H-82.5c-37.8,0-68.5-30.7-68.5-68.5l0,0V368.1
		h97c55.2,0,100-44.9,100-100.4S1.2,167.3-54,167.3s-100,44.9-100,100.4v288.7c0,39.5,32,71.5,71.5,71.5l0,0h230
		c0.8,0,1.5-0.7,1.5-1.5V165.8H146z" />
	<use href="#icon-4" transform="matrix(1, 0, 0, 1, -154, 163)" />
</g>
<text transform="matrix(1 0 0 1 -108.0532 421.0016)"><tspan x="0" y="0" class="bold" font-size="18px">LEAVE IT TO THE </tspan><tspan x="0" y="21.6" class="bold" font-size="18px">PROFESSIONALS</tspan></text>
<rect x="-108.1" y="464.8" fill="none" width="239.6" height="142.7" />
<text transform="matrix(1 0 0 1 -108.0532 476.3154)"><tspan x="0" y="0">You choose whether you are </tspan><tspan x="0" y="19.2">comfortable with one of our </tspan><tspan x="0" y="38.4">investigators contacting you if we </tspan><tspan x="0" y="57.6">need more information. </tspan><tspan x="0" y="76.8">Once your report has been </tspan><tspan x="0" y="96">logged it is reviewed by </tspan><tspan x="0" y="115.2">experienced investigators at PKF.</tspan></text>
<g>
	<text transform="matrix(1 0 0 1 -127.9864 421.0012)" class="bold" font-size="18px">4.</text>
</g>
</svg>

<!-- ------MOBILE GRAPHICS------- -->

<svg v-if="num==1" class="infographic mobile d-none d-sm-block d-lg-none w-100" viewBox="-70.1 276.5 533.6 272.5">
<g>
	<g id="KBF-Web-Infographic">
		<path opacity="0.8" fill="var(--info-bg-1)" enable-background="new    " d="M443.4,296.7c-12.9-12.9-30.4-20.2-48.7-20.2H21.9
			c-50.8,0-91.9,43.2-91.9,96.4s41.2,96.4,91.9,96.4s91.9-43.2,91.9-96.4v-93.5h280.8c36.5,0,66,29.6,66,66l0,0v200.9H-33.9v2.8
			h497.4V345.4C463.5,327.2,456.3,309.6,443.4,296.7z" />
			<use href="#icon-1" transform="matrix(1.65, 0, 0, 1.65, -364, 21)" />
	</g>
</g>
<text transform="matrix(1 0 0 1 175.1543 345.1427)"><tspan x="0" y="0" class="bold" font-size="18px">SUBMIT REPORT OR CALL</tspan><tspan x="0" y="21.6" class="bold" font-size="18px">THE NUMBER ABOVE</tspan></text>
<rect x="175.2" y="418.6" fill="none" width="256.8" height="161.8" />
<text transform="matrix(1 0 0 1 175.1543 408.5178)"><tspan x="0" y="0">Whether you report online or call, </tspan><tspan x="0" y="19.2">the process is safe and simple. You </tspan><tspan x="0" y="38.4">are guided through a short set of </tspan><tspan x="0" y="57.6">questions to gather as much </tspan><tspan x="0" y="76.8">information as possible.</tspan><tspan v-if="$store.state.org.email" x="0" y="96">You can also email PKF directly</tspan><tspan v-if="$store.state.org.email" x="0" y="115.2">at <a :href="'mailto:'+$store.state.org.email">{{$store.state.org.email}}</a></tspan></text>
<g>
	<text transform="matrix(1 0 0 1 152.5576 345.1427)" class="bold" font-size="18px">1.</text>
</g>
</svg>


<svg v-else-if="num==2" class="infographic mobile d-none d-sm-block d-lg-none w-100" x="0px" y="0px" viewBox="-28 255 553.7 283" enable-background="new -28 255 553.7 283" xml:space="preserve">
<g>
	<g>
		<path opacity="0.8" fill="var(--info-bg-2)" enable-background="new    " d="M-7,275.9c13.4-13.4,31.5-21,50.5-20.9h386.8
			c52.7,0,95.4,44.8,95.4,100S483,455,430.3,455s-95.4-44.8-95.4-100v-97H43.5C5.7,258-25,288.7-25,326.5V535h513.2v3H-28V326.5
			C-28,307.5-20.5,289.3-7,275.9z" />
		<use href="#icon-2" transform="matrix(1.65, 0, 0, 1.65, 23, 250)" />
	</g>
</g>
<text transform="matrix(1 0 0 1 34.75 322.7522)"><tspan x="0" y="0" class="bold" font-size="18px">TELL US AS MUCH AS </tspan><tspan x="0" y="21.6" class="bold" font-size="18px">YOU CAN</tspan></text>
<rect x="34.8" y="366.6" fill="none" width="234.6" height="142.7" />
<text transform="matrix(1 0 0 1 34.75 378.0669)"><tspan x="0" y="0">The more detail you can</tspan><tspan x="0" y="19.2">provide, the better. Specifics </tspan><tspan x="0" y="38.4">such as events, dates, names </tspan><tspan x="0" y="57.6">and locations all make it easier </tspan><tspan x="0" y="76.8">to look into the matter properly.</tspan><tspan x="0" y="96">You always have the option to </tspan><tspan x="0" y="115.2">remain anonymous.</tspan></text>
<g>
	<text transform="matrix(1 0 0 1 14.8167 322.7518)" class="bold" font-size="18px">2.</text>
</g>
</svg>

<svg v-else-if="num==3" class="infographic mobile d-none d-sm-block d-lg-none w-100" x="0px" y="0px" viewBox="-28 255 553.7 283" enable-background="new -28 255 553.7 283" xml:space="preserve">
<g>
	<g>
		<path opacity="0.8" fill="var(--info-bg-1)" enable-background="new    " d="M504.7,275.9c-13.4-13.4-31.6-21-50.6-20.9H67.4
			C14.7,255-28,299.8-28,355s42.7,100,95.4,100s95.4-44.8,95.4-100v-97h291.4c37.8,0,68.5,30.6,68.5,68.5l0,0V535H9.5v3h516.2V326.5
			C525.7,307.5,518.2,289.3,504.7,275.9z" />
		<use href="#icon-3" transform="matrix(1.65, 0, 0, 1.65, -333, -8)" />
	</g>
</g>
<text transform="matrix(1 0 0 1 224.1469 329.903)" class="bold" font-size="18px">STAY IN TOUCH</text>
<rect x="224.1" y="354.7" fill="none" width="271.9" height="158.2" />
<text transform="matrix(1 0 0 1 224.1469 366.2178)"><tspan x="0" y="0">You are issued with a unique Report </tspan><tspan x="0" y="19.2">Number. You can also select a </tspan><tspan x="0" y="38.4">password. The password is optional </tspan><tspan x="0" y="57.6">but if you want to check on the status </tspan><tspan x="0" y="76.8">of your report or provide further </tspan><tspan x="0" y="96">evidence later, you will need one.</tspan></text>
<g>
	<text transform="matrix(1 0 0 1 204.2136 329.9026)" class="bold" font-size="18px">3.</text>
</g>
</svg>

<svg v-else class="infographic mobile d-none d-sm-block d-lg-none w-100" x="0px" y="0px" viewBox="-28 255 553.6 283" enable-background="new -28 255 553.6 283" xml:space="preserve">
<g>
	<path opacity="0.8" fill="var(--info-bg-2)" enable-background="new    " d="M-7.1,275.9c13.4-13.4,31.6-21,50.6-20.9h386.7
		c52.7,0,95.4,44.7,95.4,100s-42.7,100-95.4,100s-95.4-44.8-95.4-100v-97H43.5C5.7,258-25,288.7-25,326.5V535h513.2v3H-28V326.5
		C-28.1,307.5-20.6,289.3-7.1,275.9z" />
	<!-- graphic -->
	<use href="#icon-4" transform="matrix(1.65, 0, 0, 1.65, 28, 255)" />
</g>
<text transform="matrix(1 0 0 1 34.9005 322.4521)"><tspan x="0" y="0" class="bold" font-size="18px">LEAVE IT TO THE </tspan><tspan x="0" y="21.6" class="bold" font-size="18px">PROFESSIONALS</tspan></text>
<rect x="34.9" y="366.3" fill="none" width="255.1" height="142.7" />
<text transform="matrix(1 0 0 1 34.9005 377.7659)"><tspan x="0" y="0">You choose whether you are </tspan><tspan x="0" y="19.2">comfortable with one of your </tspan><tspan x="0" y="38.4">investigators contacting you if we </tspan><tspan x="0" y="57.6">need more information. </tspan><tspan x="0" y="76.8">Once your report has been logged, </tspan><tspan x="0" y="96">it is reviewed by experienced </tspan><tspan x="0" y="115.2">investigators at PKF.</tspan></text>
<g>
	<text transform="matrix(1 0 0 1 14.9672 322.4518)" class="bold" font-size="18px">4.</text>
</g>
</svg>
</div>
</template>

<script>
import InfoGraphicIcons from './InfoGraphicIcons'
// import D1 from '@/assets/d-01.svg?inline'
export default {
	name: 'HomeInfoGraphic',
	components: {'info-graphic-icons': InfoGraphicIcons},
	props: {num: Number}
}
</script>

<style lang="scss">
.infographic{
	max-height: 440px;
	&.mobile {
		max-height: 240px;
	}
	a{
		text-decoration: underline;
	}
	.semi {
		opacity: .8;
	}
	.text-1 {
		fill: var(--info-1-text);
	}

}
body.ie .infographic{
	min-height: 400px;
	&.mobile {
		min-height: 240px;
	}
}
</style>