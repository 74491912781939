<template>
	<div class="mb-2" :class="{'text-mid-gray': file.state}">
		<div>
			<component :is="hasDisclosure ? 'a': 'span'" :href="downloadFileURL" target="_blank">
				<b-icon-file-text-fill class="mr-2" />
				<span class="nowrap mr-3">{{file.fileName}}</span>
			</component>
			<a href="#" title="Delete File" @click.prevent="remove"><b-icon-x /></a>
		</div>
		<div v-if="file.state" class="fs-14 w-100">
			<b-progress v-if="file.state=='Uploading'" :value="file.progress" animated />
			<span v-else>{{file.state}} </span>
		</div>
	</div> 
</template>

<script>
import { BIconFileTextFill, BProgress } from 'bootstrap-vue'
export default {
	components: {
		BIconFileTextFill,
		BProgress
	},
	props: ['file'],
	computed: {
		hasDisclosure() {
			return this.$store.state.disclosureId
		},
		downloadFileURL() {
			if (!this.hasDisclosure) return null
			return this.$store.getters.apiDisclosurePath + 'docs/' + this.file.fileId
		},
	},
	methods: {
		async remove() {
			const sure = await this.$store.dispatch('confirmer/ask', {title: 'Are you sure?', body: `You are about to delete file: ${this.file.fileName}.`})
			if (sure) this.$emit('remove')
		}
	},
}
</script>