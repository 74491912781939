import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './globalComponents'

Vue.config.productionTip = false
const qi = window.location.href.indexOf('?')
let q
if (qi > -1) q = window.location.href.slice(qi + 1)
let client = q && q.length > 2 && q.length < 6 ? q : window.sessionStorage.getItem('org')
if (!client || client.toLowerCase() == 'null') client = 'DFLT'

const isLive = !window.location.href.includes('localhost')
store.commit('set', {isLive})
console.log('client = ', client);

let clientError = false
router.onReady(() => { 
	if (clientError) router.push('/whoops') 
});

(async () => {
	const isLoggedIn = await checkLogin()
	try {
		if (!isLoggedIn) await store.dispatch('init', client)
	} catch (e) {
		clientError = true
	}
	new Vue({
		router,
		store,
		render: h => h(App),
	}).$mount('#app')
})()


function checkLogin() {
	const expiry = window.sessionStorage.getItem('expiry')
	const disclosureId = window.sessionStorage.getItem('disclosureId')
	if (disclosureId && expiry && new Date(expiry).getTime() > new Date().getTime()) {
		const o = {
			disclosureId,
			expirationUtc: expiry,
			orgCode: window.sessionStorage.getItem('orgCode'),
			serviceOption: window.sessionStorage.getItem('serviceOption'),
			isDisclosureSubmitted: window.sessionStorage.getItem('isDisclosureSubmitted') == 'true',
		}
		return store.dispatch('login/isLoggedIn', o)
	} else {
		return Promise.resolve(false)
	} 
}
