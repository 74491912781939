<template>
	<b-form-group
		:label="label"
		label-for
		class=" password-form-group"
		:class="{'is-invalid': isInvalid}"
	>
		<div class="position-relative pb-1">
			<b-form-input
				ref="password"
				class="pr-4"
				
				:value="password"
				:type="passwordVisible? 'text' : 'password'"
				:autofocus="autofocus"
				:placeholder="placeholder"
				:autocomplete="isNew ? 'new-password': 'current-password'"
				@focus="focussed = true"
				@blur="focussed = false"
				@keyup="$emit('update:password', $event.target.value)"
				@input="$emit('update:password', $event)"
			/>
			<a href="#" class="input-icon" @click.prevent="passwordVisible =! passwordVisible">
				<b-icon-eye-slash-fill v-if="passwordVisible" />
				<b-icon-eye-fill v-else />
			</a>
		</div>
		<slot />
		<p v-if="!isInvalid && isNew" class="small"><a href @click.prevent="showPasswordRules">Click here for password rules</a></p>
		<div class="invalid-feedback"><slot name="error">Password must be at least 10 characters long</slot></div>
	</b-form-group>
</template>

<script>
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-vue'
import {hasPassword} from '@/mixins'
export default {
	name: 'PasswordFormGroup',
	components: {
		'b-icon-eye-fill': BIconEyeFill,
		'b-icon-eye-slash-fill': BIconEyeSlashFill,
	},
	mixins: [hasPassword],
	props: {
		password: String,
		showInvalid: Boolean,
		label: {
			type: String,
			default: 'Create Password'
		},
		autofocus: {
			type: Boolean,
			default: false
		},
		placeholder: { 
			type: String,
			default: 'Password'
		},
		isNew: Boolean
	},
	data() {
		return {
			passwordVisible: false,
			focussed: false
		}
	},
	computed: {
		isInvalid() {
			return this.showInvalid && (this.isNew && !this.passwordIsValid())
		},
		validClass() {
			if (!this.focussed || !this.isNew || !this.password?.length) return 
			return this.passwordIsValid() ? 'valid' : 'invalid'
		}
	},
	watch: {
		isInvalid(val) {
			if (val && this.autofocus) this.$refs.password.focus()
		}
	},
	methods: {
		showPasswordRules() {
			this.$store.commit('confirmer/activate', {
				title: "Password rules",
				hasCancel: false,
				saveLabel: 'OK',
				body: `Password must be at least 10 characters.<br>
				Password must not contain a part of your name or email.<br>
				Password must contain characters from at least three of the following categories:<ul><li>uppercase letters</li><li>lowercase letters</li><li>numbers</li><li>symbols and non-European alphabet characters.</li></ul>`
			})
		},
		focus() {
			this.$refs.password?.focus()
		}

	}
	// mounted() {
	// 	setTimeout(() => {
	// 		console.log('Password', this.password, this.$refs.password.value);
	// 	}, 500);
	// },
}
</script>

<style lang="scss">
.password-form-group{
	.valid {
		border-bottom: 2px solid $success;
	}
	.invalid {
		border-bottom: 2px solid $warning;
	}
	&.is-invalid{
		@extend %invalid-form-group;
		a.input-icon{
			color: $warning;
		}
	}
}
a.input-icon{
	position: absolute;
	right: 9px;
	top: 10px;
}

</style>