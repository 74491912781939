<template>
	<div v-if="$store.state.org.hasLogo" :class="{square}"><img class="client-logo" :src="src"></div>
</template>

<script>
export default {
	name: 'ClientLogo',
	props: {
		square: Boolean,
	},
	computed: {
		src() {
			return this.$store.getters.apiClientPath + 'logo'
		}
	}
}
</script>
<style lang="scss" scoped>
div.square{
	position: relative;
	max-width: 207px;
	width: 100%;
	background-color: #fff;
	&:after {
		content: '';
		display: block;
		padding-bottom: 100%;
	}
	img {
		position: absolute;
		top: 20px;
		bottom: 20px;
		left: 26px;
		right: 26px;
		margin: auto;
		max-width: calc(100% - 52px);
		max-height: calc(100% - 40px);
		// width: 100%;
		// height: 100%;
		width: -moz-available;          /* For Mozzila */
		// width: -webkit-fill-available;  /* For Chrome */
		// width: stretch;  
	}
}
body:not(.ie) {
	div.square img{
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}
div:not(.square){
	display: inline-block;
	background: #fff;
	padding: 10px 20px;
	> img{
		max-width: 210px;
		max-height: 150px;
		min-width: 20px;
		min-height: 20px;
	}	
} 
</style>

