import axios from 'axios'
import setState from "../common/setState";

// 0 = radio, 1 = checkbox, 2 = textarea
// const addResponses(qs) {

// }
const convertQuestionsIn = q => {
	if (q.questionType == 2) {
		q.response = q.responseText
	} else if (q.responseChoices) {
		q.response = []
		for (let i = 0; i < q.choicesCount; i++) {
			const choice = q['choice' + (i + 1)]
			if (q.responseChoices[i]) q.response.push(choice)
		}
		if (!q.questionType) q.response = q.response[0]
	}
}
const convertQuestionsOut = q => {
	if (!q.response) return null
	const o = {questionId: q.questionId}
	if (q.questionType == 2) {
		o.responseText = q.response
	} else {
		o.responseChoices = Array(8).fill(0)
		for (let i = 0; i < q.choicesCount; i++) {
			const choice = q['choice' + (i + 1)]
			if (q.questionType) {
				if (q.response.includes(choice)) o.responseChoices[i] = 1
			} else if (q.response == choice) o.responseChoices[i] = 1
		}
	}
	return o
}
const checkForDetailsChanges = (qs, oldQs) => {
	qs.forEach((q, i) => {
		const oldQ = oldQs[i]
		if (!oldQ) return
		if (!q.response) q.response = oldQ.response
		if (q.questionText != oldQ.questionText && oldQ.response && oldQ.response.length) q.questionChanged = true
	})
}
const state = {
	oldQ4: '',
	savedDetails: null,
	background: [],
	details: [],
	additional: [],
	backgroundHasRetrieved: false,
	additionalHasRetrieved: false,
}
const mutations = {
	...setState,
	reset(state) {
		state.background = []
		state.details = []
		state.additional = []
	}
}
const actions = {
	loadQuestions({state, commit, dispatch, getters, rootState}, questions) {
		const getResponses = rootState.isRetrieving
		return new Promise(resolve => {
			// if the questions have been loaded, use loaded questions
			// unless we're retrieving them after logging in. 
			if (state[questions].length && (!getResponses || (getResponses && state[questions + 'HasRetireved']))) return resolve(state[questions])
			// const postVars = getters.postVars(getResponses)
			axios.get(getters.apiPath(questions, getResponses)).then(response => {
				if (getResponses) {
					response.data.forEach(q => convertQuestionsIn(q))
					commit('set', {[questions + 'HasRetireved']: true})
				}
				commit('set', {[questions]: response.data})
				return resolve(response.data)
			})
		})
	},
	// loading details questions is handled differently
	loadDetails({state, commit, getters, rootState}, questions) {
		const getResponses = rootState.isRetrieving
		return new Promise(resolve => {
			// details changes depending on q4 response
			const q4 = convertQuestionsOut(state.background[3]).responseChoices
			// if it's been loaded and q4 hasn't changed, use stored questions
			if (state.details.length) {
				if (state.oldQ4 == q4.join()) return resolve(state.details)
				commit('set', {savedDetails: [...state.details]})
			}
			// const postVars = getters.postVars(getResponses)
			// postVars.questionSetSeed = q4
			// axios.post(getters.apiPath(questions), getters.postVars(getResponses)).then(response => {
			axios.post(getters.apiPath(questions, getResponses), {questionSetSeed: q4}).then(response => {
				if (getResponses) response.data.forEach(q => convertQuestionsIn(q))
				if (state.savedDetails) checkForDetailsChanges(response.data, state.savedDetails)
				commit('set', {[questions]: response.data})
				return resolve(response.data)
			})
		})
	},

	
	// save({state, rootState}) {

	// }
}
const getters = {
	apiPath: (state, getters, rootState, rootGetters) => (q, r) => {
		const base = r ? `${rootGetters.apiDisclosurePath}questions-responses` : `${rootGetters.apiBasePath}questions`
		const url = `${base}/${q}`
		return q == 'additional' && !r ? `${url}/${rootState.org.code}` : url
	},
	responses: (state) => (qs) => {
		const r = state[qs].filter(q => q.response)
		return r.map(q => convertQuestionsOut(q))
	},
	postVars: (state, getters, rootState) => (r) => {
		if (r) {
			return {
				disclosureId: rootState.disclosureId,
				isResponseIncluded: true
			} 
		} 
		return {}
	}
}

export default {
	namespaced: true,
	// mutations: {...mutations, ...setState},
	mutations,
	state,
	actions,
	getters
}