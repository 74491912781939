<template>
	<div>
		<b-form-group
			v-for="(q, i) in questions"
			:id="`q${q.questionId}`"
			:key="q.id"
			:label="q.questionText"
			:label-for="`q${q.questionId}`"
			:label-class="{optional:!q.isMandatory}"
			:class="[{'is-invalid': !q.isValid, 'is-changed': q.questionChanged}, 'type-'+q.questionType]"
		>
			<div v-if="q.questionInstruction" class="sub-label">{{q.questionInstruction}}</div>
			<b-form-radio-group
				v-if="!q.questionType"
				v-model="q.response"
				:options="getOptions(q)" 
				@change="$emit('change', q, i)"
			></b-form-radio-group>
			<b-form-checkbox-group
				v-else-if="q.questionType == 1"
				v-model="q.response"
				:options="getOptions(q)"
				stacked
				@change="$emit('change', q, i)"
			></b-form-checkbox-group>
			<b-form-textarea
				v-else
				v-model="q.response"
				rows="7"
				@input="$emit('change', q, i)"
			></b-form-textarea>
			<div class="invalid-feedback">{{getFeedback(q)}}</div>
		</b-form-group>
	</div>
</template>

<script>
export default {
	name: 'DynamicFormControls',
	props: {questions: Array},
	methods: {
		getFeedback(q) {
			if (q.feedback) return q.feedback
			else if (q.questionChanged) return 'The question has changed since you changed your response on page 1. Please review your answer.'
			const a = ['Please choose an option', 'Please choose at least one option', 'This field is required']
			return a[q.questionType]
		},
		getOptions(q) {
			const a = []
			for (let i = 0; i < q.choicesCount; i++) {
				a.push(q['choice' + (i + 1)])
			}
			return a
		},
	}
}
</script>

<style lang="scss">
.form-group{

	&.type-0,&.type-1{
		position: relative;
		&::before{
			position: absolute;
			content: '';
			top: -15px;
			right: -15px;
			bottom: -15px;
			left: -15px;
			background:$invalid-bg;
			z-index: -1;
			opacity: 0;
			transition: opacity .2s;
		}
	}
	textarea {
		transition: all .2s;
	}
	.invalid-feedback {
		display: none;
		color: $warning;
	}
}

.is-submitted .form-group.is-invalid, .form-group.is-changed {
	
	&.type-0, &.type-1{
		&::before{
			opacity: 1;
		}
	}
	textarea {
		background:$invalid-bg;
		border: 1px solid $warning;
	}
	.invalid-feedback {
		display: block;
	}
}
</style>