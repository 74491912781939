<template>
	<b-modal
		id="confirm-modal"
		ref="confirm-modal"
		v-model="confirmer.active"
		size="lg"
		centered
		hide-header
		hide-footer
		static
		return-focus="window"
		@show="onShow"
		@ok="confirm"
		@hide="hide"
		@cancel="cancelled"
		@hidden="hidden"
		@keydown.native.enter="confirm"
	>
		<template #default="{ ok, cancel }">
			<div class="mb-4">
				<h2 class="mb-4">
					{{confirmer.title}}
				</h2>
				<div v-if="confirmer.isUnsafeFile">
					<!-- <p>Due to the type of file you are uploading, we are unable to remove the file properties data for you. Old versions of Microsoft office files such as doc, and xls will need to have the file properties removed manually.</p>
<p>For details on how to remove file properties data yourself, click <router-link to="/guide">here</router-link> to see a quick guide.</p>
<p>If you are still unsure, you can email the file to [dedicated org pkf email address] instead of uploading it, where it will be manually checked for identifying information.</p> -->
					<div class="alert alert-warning">If you wish to remain anonymous, please ensure you have removed all identifiable information (e.g. your name or information that is likely to identify you) from both the file and its document/security properties.</div>
					<p>This upload will automatically strip only the document properties of PDF, DOCX, XLSX and PPTX file types.<br>Most files can be saved as a pdf, you can read how <a href="https://www.wikihow.com/Save-a-PDF-File" target="_blank">here</a>.</p>
					<p>Files can be uploaded after the disclosure has been submitted, provided you create a password to log in.</p>
					<p>If you are still unsure, you can email the file to <a :href="emailLink" target="_blank">{{orgEmail}}</a> instead of uploading it, where it will be manually checked for identifying information.</p>
				</div>
				<p v-else v-html="confirmer.body" />
			</div>
			<div class="d-flex align-items-center">
				<b-button v-if="confirmer.hasCancel" variant="outline-warning" @click="cancel">
					Cancel
				</b-button>
				<b-button
					ref="ok"
					class="ml-auto"
					@click="ok"
				>
					{{confirmer.saveLabel}}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
// import { CONFIRM, CANCEL, HIDE } from '@/store/const/confirmer'

export default {
	name: 'ConfirmModal',
	computed: {
		confirmer() {
			return this.$store.state.confirmer
		},
		orgEmail() {
			return this.$store.state.org.email
		},
		emailLink() {
			let link = 'mailto:' + this.orgEmail
			if (this.$store.state.disclosureId) link += '?subject=Additional documents for disclosure ' + this.$store.state.disclosureId
			return link
		}
	},
	watch: {
		$route() {
			console.log('modal on route change');
			this.$bvModal.hide('confirm-modal')
			if (this.confirmer.resolve) this.confirmer.resolve(false)
		}
	},
	methods: {
		confirm() {
			console.log('confirmed!');
			this.confirmer.resolve?.(true)
			this.$bvModal.hide('confirm-modal')
		},
		cancelled(e) {
			this.confirmer.resolve?.(false)
		},
		hide(e) {
			this.confirmer.resolve?.(false)
		},
		hidden() {
			console.log('Confirm hidden');
		},
		onShow() {
			this.$nextTick(() => {
				console.log('modal - should focus', this.$refs.ok);
				this.$refs.ok.focus()
			})
		},
	}
}
</script>
