import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueSignalR from '@latelier/vue-signalr'
import VueClipboard from 'vue-clipboard2'

import './utils/filters'

import {
	LayoutPlugin,
	ButtonPlugin,
	FormInputPlugin,
	InputGroupPlugin,
	FormGroupPlugin,
	ToastPlugin,
	BAlert,
	BForm,
	BFormCheckbox,
	BFormFile,
	BFormSelect,
	BFormTextarea,
	BFormRadioGroup,
	BFormCheckboxGroup,
	ModalPlugin,
	BIconFileText,
	BIconFileTextFill,
	BIconTelephoneFill,
	BIconEnvelopeFill,
	BIconX
} from 'bootstrap-vue'
import { MobileMenu, AboutPods, DynamicFormControls } from '@/components'
import {
	Logo,
	ClientLogo,
	IconSecure,
	BtnBack,
	BtnSave,
	SubmitProgress,
	PasswordFormGroup,
	ReportID,
	SelectLoggedIn,
	Spinner
} from '@/components/ui'

import Axios from 'axios'
Vue.prototype.$http = Axios
 

Vue.mixin({
	computed: {
		orgName() {
			return this.$store.state.org.orgName 
		},
		term() {
			return this.$store.state.org.discloserTerm
		}
	},
})
Axios.interceptors.request.use(
	config => {
		config.withCredentials = true;
		return config;
	},
);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

const chatDomain = window.location.href.includes('localhost') ? 'https://pkftest.com/dms' : '/dms'
Vue.use(VueSignalR, chatDomain + '/chathub')
Vue.use(VueMeta)

// set defaults in first bootstrap use
Vue.use(LayoutPlugin, { BButton: { variant: 'success' } })
Vue.use(ButtonPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)
Vue.component('b-alert', BAlert)
Vue.component('b-form', BForm)
Vue.component('b-form-checkbox', BFormCheckbox)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-form-file', BFormFile)
Vue.component('b-form-textarea', BFormTextarea)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)
Vue.component('b-icon-file-text', BIconFileText)
Vue.component('b-icon-file-text-fill', BIconFileTextFill)
Vue.component('b-icon-telephone-fill', BIconTelephoneFill)
Vue.component('b-icon-envelope-fill',	BIconEnvelopeFill)
Vue.component('b-icon-x', BIconX)

Vue.component('Logo', Logo)
Vue.component('client-logo', ClientLogo)
Vue.component('submit-progress', SubmitProgress)
Vue.component('icon-secure', IconSecure)
Vue.component('btn-back', BtnBack)
Vue.component('btn-save', BtnSave)
Vue.component('about-pods', AboutPods)
Vue.component('mobile-menu', MobileMenu)
Vue.component('password-form-group', PasswordFormGroup)
Vue.component('report-id', ReportID)
Vue.component('select-logged-in', SelectLoggedIn)
Vue.component('dynamic-form-controls', DynamicFormControls)
Vue.component('spinner', Spinner)