<template>
	<b-modal
		id="create-password"
		ref="create-password-modal"
		centered
		hide-header
		hide-footer
		static
		lazy
		size="lg"
		@ok="submit()"
		@hide="handleCancel"
	>
		<template #default="{ cancel }">
			<div class="header">
				<div class="d-flex align-items-center mb-3">
					<h2 class="mr-auto my-0">
						{{title}}
					</h2>
					<span>
						<img src="/img/secure.png" class="mr-3">
						<i>Safe, secure, confidential</i>
					</span>
				</div>
				<p v-if="!alreadySaved">
					<!-- <strong>{{props.heading}}</strong><br> -->
					<template v-if="!isSubmit">If you wish to save your report to finish later, you will need to create a password.<br><br></template>
					Creating a password is optional but you must have one if you want to:
					<ul>
						<li>
							get back to this report to add more information later
						</li>
						<li>
							find out how the matter is progressing after you have submitted it
						</li>
						<li>anonymously communicate with PKF or your organisation after submitting your report</li>
					</ul>
					For your security, we will never give out details about a submitted report, online or by phone, unless you have a password. You can still remain anonymous if you create a password.
				</p>
				<p v-else-if="hasPassword">
					<strong>Your password has been saved</strong><br>
					You can use your password and the report ID to continue the report at a later date. Once the report has been completed, you will have access to a portal for private messages and status updates regarding the report. 
				</p>
				<p v-else>
					<strong>Your report has been submitted</strong><br>
					If you have supplied contact details we may contact you at some stage to clarify or substantiate your claim. 
				</p>
			</div>
			<transition name="fade" mode="out-in">
				<b-form v-if="!alreadySaved" @submit.prevent="submit()">
					<div class="body">
						<!-- <b-form-group
							label="Report ID"
							label-for="report-id"
							class="mb-3"
						> -->
							<!-- <report-id v-if="" class="mb-3" /> -->
						<!-- </b-form-group> -->
						<password-form-group ref="password" :password.sync="password" :show-invalid="isSubmitted" class="mb-35" autofocus is-new>
							<template v-if="!isSubmit" slot="error">You must enter a password of at least 10 characters before you can save a report.</template>
							<template v-else slot="error">Please enter a secure password to submit report. Alternatively, you can click the "Submit without Password" link below.</template>
						</password-form-group>
						<b-form-group
							label="Email"
							label-for="Email"
							label-class="optional"
							class="mb-3"
						>
							<b-form-input id="modalEmail" v-model="email" type="email" name="email" autocomplete="email" :readonly="fixautofill" @focus="fixautofill=false"></b-form-input>
						</b-form-group>
							<p class="font-italic sm">
								<b>Why do we ask for an email address?</b> If you forget your password, you can request a password reset link sent to this email address. If you don’t provide an email address, please make very sure to remember your password. 
							</p>
					</div>
					<div class="footer">
						<!-- <div v-if="isSubmit" class="mb-4">
							<b-button class="download-report py-3" variant="light"><b-icon-file-text /> Download a copy of my Report</b-button>
						</div> -->
						<div class="d-flex align-items-center">
							<b-button
								class="mr-auto"
								variant="outline-primary"
								@click="cancel()"
							>
								Back
							</b-button>
							<a v-if="isSubmit" href="#" class="mr-3" :title="noPWDWarn" @click.prevent="submit(false)">Submit without Password</a>
							<b-button
								type="submit"
							>
								{{isSubmit ? 'Submit' : 'Save'}}
							</b-button>
						</div>
					</div>
				</b-form>
				<div v-else>
					<div class="body">
						<report-id />
					</div>
					<div class="footer">
						<div v-if="isSubmit " class="mb-4">
							<b-button class="download-report py-3" variant="light" @click.prevent="downloadReport()"><b-icon-file-text /> Download a copy of my Report</b-button>
						</div>
						<div class="d-flex">
							<b-button
								class="ml-auto"
								@click.prevent="clickContinue"
							>
								Continue
							</b-button>
						</div>
					</div>
				</div>
			</transition>
		</template>
	</b-modal>
</template>

<script>
import {isCreatePassword, canDownloadReport} from '@/mixins'
export default {
	name: 'CreatePasswordModal',
	mixins: [isCreatePassword, canDownloadReport],
}
</script>

<style lang="scss">
$y-pad: 35px;

#create-password{
	.modal-body{
		padding: 0; 
	}
	.header{
		padding: 30px $y-pad 10px;
	}
	.body{
		padding: 25px $y-pad;
		background-color: $secondary;
		.sm label{
			display: block;
			font-size: 1rem;
		}
	}
	.footer{
		padding: $y-pad;
	}
}
</style>