<template>
	<div class="home loaded">
		<div class="w-100 d-flex flex-column">
			<DETBanner v-if="$store.state.org.code == 'DET'" />
			<component :is="$store.state.isOptus ? 'HomeBannerOptus' : 'HomeBanner'" name="homeBanner" />
			<div id="icons" ref="expect">
				<b-container id="more-container" class="py-6 px-main">
					<b-row>
						<b-col><h3 class="mb-4 mb-md-5 text-center">What to expect if you make a report</h3></b-col>
					</b-row>
					<b-row class="maw-lg m-xl-auto justify-content-center">
						<b-col v-for="n in 4" :key="n" md="6" lg="3">
							<home-info-graphic :num="n" class="mb-35 mb-lg-0" />
						</b-col>
					</b-row>
					<b-row>
						<b-col class="text-center">
							<b-button to="/step1" align="center" class="btn-submit-report d-inline-flex align-items-center px-3 mt-4 mt-md-6">
								<icon-secure width="24" height="24" class="mr-3" /> Submit Report
							</b-button>
						</b-col>
					</b-row>
				</b-container>
			</div>
			<div id="ticks" class="bg-gray-200">
				<h3 v-if="$store.state.isOptus" class="mb-0 mt-4 text-center px-main">Why does Optus use PKF?</h3>
				<h3 v-else class="mb-0 mt-4 text-center px-main">How does disclosing through the PKF Integrity Portal protect me?</h3>
				<b-container ref="more" class="px-main pt-4 pt-md-5">
					<b-row class="justify-content-center">
						<b-col id="ticks-wrapper" md="7" lg="5">
							<div class="has-tick">
								<h2>We are independent</h2>
								<p class="mb-4">PKF is an independent third-party, which manages whistleblower and reporting hotlines for both private and public sector organisations. Whatever you need to report, you can do so in confidence.</p>
							</div>
							<div class="has-tick">
								<h2>We are experienced</h2>
								<p class="mb-4">Our team is made up of senior professionals, specialising in the investigation of fraud, corruption and workplace misconduct. We come from law enforcement, private investigation and risk management backgrounds. Reports of suspected unethical behaviour or misconduct can be investigated meticulously.</p>
							</div>
							<div class="has-tick">
								<h2>You can choose to remain anonymous</h2>
								<p>We take all reports received in the strictest confidence. In fact, you can make the report completely anonymously if you wish. Our priority is to ensure that you feel safe making your report and confident that it will be handled in accordance with your wishes and expectations.</p>
							</div>
							<div v-if="$store.state.isOptus" class="has-tick">
								<h2>You are in safe hands</h2>
								<p>Our priority is to ensure you feel safe making your report and confident that it will be handled in accordance with your wishes and expectations. Our portal provides a chat tool that supports anonymous communication, so that you can follow up on your matter at any time if you wish.</p>
							</div>
						</b-col>
						<b-col v-if="$store.state.org.includeVideo !== false" md="5" lg="4" offset-lg="1" class="text-center text-md-right mt-4 mt-md-0">
							<p class="text-primary text-left fs-15">This video provides further information about the PKF Integrity independent hotline service and also the experiences of whistleblowers that have used the service</p>
							<video-player :src="video" />
							<!-- <video controls width="100%">
								<source :src="video" type="video/mp4">
							</video> -->
							<!-- <video src="https://drive.google.com/file/d/1Q7_vvcrCQERzZxJmw0zYwdHs2AqGA9IK/view?usp=sharing" width="100%"></video> -->
							<!-- <a href="" class="arrow mb-1 d-inline-block">Learn how you can help by reporting an incident.</a><br>
							<div class="stripe cursor-pointer" @click="$router.push('/about')"><img src="/img/home01.jpg" alt="" class=""></div> -->
						</b-col>
					</b-row>
				</b-container>
			</div>
			<b-container v-if="$store.state.org.clientMessage" id="message" class="pt-6 px-main">
				<b-row class="justify-content-center">
					<!-- <b-col md="10">
						<h3 class="mb-4">A Message from {{orgName}}</h3>
					</b-col>
				</b-row>
				<b-row class="justify-content-center">
					<b-col md="3" class="text-center" v-if="!$store.state.isOptus">
						<client-logo class="mb-3 p-0" />
					</b-col>
					<b-col id="message-col" md="7">
						<div class="message-parent d-flex align-items-start pb-6">
							<client-logo v-if="$store.state.isOptus" class="message-logo mb-3 p-0" square />

							<div class="client-message" v-html="message" />
						</div>
					</b-col> -->
					<b-col id="message-col" md="10" lg="8">
						<!-- <client-logo v-if="!$store.state.isOptus" class="mb-3 p-0" /> -->
						<h3 class="mb-0 text-center">A Message from {{orgName}}</h3>
						<div class="message-parent d-flex flex-column flex-md-row align-items-start pb-6 pt-4 pt-md-5">
							<client-logo class="message-logo mb-3 p-0 border-primary border mr-3" square />

							<div class="client-message" v-html="message" />
						</div>
					</b-col>
				</b-row>
			</b-container>
			<!-- <about-pods /> -->
		</div>
	</div>
</template>

<script>
import { HomeInfoGraphic, IconPhone, DETBanner, HomeBanner, HomeBannerOptus } from "@/components/ui";
import { VideoPlayer } from "@/components";
// import { canSanitize } from "@/mixins"
import * as DOMPurify from 'dompurify';
import path from 'path'
export default {
	name: 'Home',
	components: { HomeInfoGraphic, IconPhone, DETBanner, HomeBanner, HomeBannerOptus, VideoPlayer },
	// mixins: [canSanitize],
	computed: {
		video() {
			return (this.$store.state.isLive ? '' : 'https://compliancenetfta.site') + '/videos/Main_Video.mp4'
		},
		message() {
			const m = this.$store.state.org.clientMessage
			if (!m) return
			const config = {
				ALLOWED_TAGS: ['div', 'strong', 'em', 'del', 'a', 'ul', 'li', 'ol', 'br', 'p', '#text'], 
				ALLOWED_ATTR: ['href', 'target'],
				KEEP_CONTENT: false, // remove content from non-allow-listed nodes too
			};
			// build fitting regex
			// must start with http || https
			const prefix = RegExp('^http(s?):', 'im');
			// must not be linked to these filetypes
			const ext = ['.exe', '.msi', '.bat', '.vb', '.vbs', '.ws', '.wsf', '.scf']

			// Add a hook to enforce URI scheme allow-list
			DOMPurify.addHook('afterSanitizeAttributes', function (node) {
			// build an anchor to map URLs to
				var anchor = document.createElement('a');

				// check all href attributes for validity
				if (node.hasAttribute('href')) {
					anchor.href = node.getAttribute('href');
					if (anchor.protocol && (!node.getAttribute('href').match(prefix) || ext.includes(path.extname(anchor.href)))) {
						node.removeAttribute('href');
					}
					node.setAttribute('target', '_blank');
				}
			});
			return DOMPurify.sanitize(m, config)
		}
	},
	metaInfo: {
		title: 'Home',
	}
}
</script>

<style lang="scss">
.client-message{ 
	word-wrap: break-word;
	a {
	text-decoration: underline;
}}
.bg-swirl {

	background-color: var(--home-hero-bg);
	background-image: url(/img/swirl.svg);
	background-repeat: no-repeat;
	background-size: calc(100% + 1300px);
	background-position: right -20% top 30%;
}
a.text-lime {
	&:hover {
		color: #ffffff;
	}
}

.has-tick {
	padding-left: 60px;
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: -4px;
		left: 0;
		width: 36px;
		height: 35px;
		background: url(/img/tickCirc.svg) no-repeat;
		background-size: cover;
	}
	p {
		color: #919191;
	}
	@include media-breakpoint-down(xs) {
		padding-left: 48px;
		p {
			color: #555555;
		}
	}
}
.home {
	
	h1 {
		font-weight: normal;
		font-size: 43px;
		letter-spacing: .86px;
		line-height: 1.25em;
	}
	h2 {
		font-size: 30px;
		font-weight: 100;
	}
	h3 {
		color: $primary;
		font-size: 28px;
		font-weight: 100;
	}
	.sub-head {
		color: #cccccc;
		font-size: 18px;
		letter-spacing: .36px;
	}
	a:hover{
		text-decoration: none;
	}
	@include media-breakpoint-down(sm) {
		h1 {
			font-size: 28px;
		}
		h2 {
			font-size: 26px;
			font-weight: 400;
		}
		h3 {
			font-size: 24px;
			font-weight: 400;
		}
		.bg-swirl {
			background-position: right 5% top -400px;
			background-size: 3354px 1154px;
		}
		.message-logo{
			margin: 0 auto 1.5rem !important;
		}
	}
} 
.btn-submit-report {
	border: none;
	color: var(--btn-submit-report);
	background-color: var(--btn-submit-report-bg);
	&:hover{
		color: var(--btn-submit-report-bg);
		background-color: var(--btn-submit-report);
	}
}
</style>