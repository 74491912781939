import { hasEmail, hasPassword, canShowProblem } from './'
export default {
	mixins: [hasEmail, hasPassword, canShowProblem],
	data() {
		return {
			isSubmitted: false,
			hasPassword: false,
			fixautofill: true,
			isClosed: false
		}
	},
	computed: {
		retrieveURL() {
			return window.location.protocol + '//' + window.location.hostname + '/login' 
		},
		password: {
			get() {
				return this.$store.state.login.password
			},
			set(newVal) {
				this.$store.commit('login/set', {password: newVal})
			}
		},
		state() {
			return this.$store.state.login.state
		},
		alreadySaved() {
			return this.$store.state.saved
		},
		title() {
			return this.state == 'save'
				? 'Save Report'
				: this.alreadySaved
					? 'Report Submitted'
					: 'Submit Report'
		},
		// props() {
		// 	const o = {
		// 		save: {
		// 			title: 'Save Report',
		// 		}, 
		// 		submit: {
		// 			title: 'Create Password',
		// 		}
		// 	}
		// 	return o[this.state]
		// },
		isSubmit() {
			return this.state == 'submit'
		},
		noPWDWarn() {
			return this.isSubmit 
				? 'Creating a password is optional but you must have one if you want to log in later to find out how the matter is progressing after you have submitted it.' 
				: 'Creating a password is optional but you must have one if you want to get back to this report to add more information later or find out how the matter is progressing after you have submitted it.' 
		}
	},
	methods: {
		submit(withPassword = true) {
			this.hasPassword = withPassword
			// trying to save with a password and without a valid password
			// just make isSubmitted: true to highlight the password field
			if (withPassword && !this.passwordIsValid()) {
				this.isSubmitted = true
			} else {
				this.$store.dispatch('save').catch(e => {
					this.showProblem(e).then(r => {
						console.log('SHOW PROB THEN', e?.response?.data?.ErrorCode, this.$refs.password.focus);
						if (e?.response?.data?.ErrorCode == "PasswordValidationFailed") this.$refs.password.focus()
					})
				}) 
			}
			// submitting without password, go straight to thanks
			// if (this.isSubmit && !withPassword) {
			// 	this.gotoThanks()
			// } 
			// else will show different state with reportID 
		},
		clickContinue() {
			if (this.isSubmit) {
				console.log('continue! goto thanks');
				this.goto('/thanks')
			} else {
				this.goto('/step2')
			}
		},
		handleCancel(e) {
			console.log('handleCancel! goto thanks');
			this.fixautofill = true
			if (this.$store.state.saved && this.isSubmit && !this.isClosed) this.goto('/thanks')
			this.isSubmitted = this.hasPassword = this.isClosed = false
		},
		goto(page) {
			this.isClosed = true
			if (this.$refs['create-password-modal']) {
				this.$emit('ok')
				this.$refs['create-password-modal'].hide()
			}
			this.$router.push(page)
		},
	},
	// mounted() {
	// 	console.log('mounted', this.$store.state.login);
	// }
}