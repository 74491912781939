<template>
	<div>
		<div class="report-id-wrapper sm d-flex align-items-center pr-3 flex-wrap maw-md cursor-pointer" :class="{'bg-white':white, copied: justCopied, success}" @click="textToClipboard">
			<span class="report-id d-inline-block">{{reportID}}</span> 
			<a href="#" class="ml-3 my-1" @click.prevent="textToClipboard">❏ <span>Click to Copy</span></a> 
		</div>
		<div class="mt-2 sm"><b>What is your Report ID?</b> It uniquely identifies the matter that you are currently reporting. You will need it if you want to log back in.</div>
	</div>
</template>

<script>
export default {
	name: 'ReportID',
	props: {
		white: Boolean
	},
	data() {
		return {
			justCopied: false,
			success: false
		}
	},
	computed: {
		reportID() {
			return this.$store.state.disclosureId
		}
	},
	methods: {
		textToClipboard () {
			// const dummy = document.createElement("textarea")
			// const el = document.body
			// el.appendChild(dummy)
			// dummy.value = this.reportID
			// dummy.select()
			// dummy.setSelectionRange(0, 99999);
			// document.execCommand("copy")
			// console.log('dummy.value', dummy.value);
			// el.removeChild(dummy)
			console.log('COPY!!!!', this.reportID);
			navigator.clipboard.writeText(this.reportID)
			this.$copyText(this.reportID).then(e => {
				console.log('success copy', e);
				this.success = true
				this.justAttempted()
			}, e => {
				console.log('fail copy', e);
				this.success = false
				this.justAttempted()
			})
		},
		justAttempted() {
			this.justCopied = true
			setTimeout(() => {
				this.justCopied = false
			}, this.success ? 2000 : 3000);
		}
	}
}
</script>

<style lang="scss">

.report-id-wrapper{
	position: relative;
	&:after {
		opacity: 0;
		content: "Couldn't automatically copy, please copy manually";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1rem;
		transition: opacity .15s;
	}
	&.success:after {
		content: 'Copied to clipboard';
	}
	&.copied:after{
		opacity: 1;
	}
}
.report-id{
	padding: 0 24px;
	background-color: #CED5E5;
	font-size: 34px;
	color: #000;
	cursor: pointer;
	font-weight: 100;
	text-transform: uppercase;
	@include media-breakpoint-down(xs) {
		font-size: 30px;
	}
}
.large .report-id{
	padding: 15px 1em;
}

</style>