export default {
	methods: {
		showProblem(e, body = 'There was a problem trying to perform this action.') {
			let str = ''
			if (e?.response?.data) {
				if (e.response.data.ErrorMessage) str = e.response.data.ErrorMessage
				else if (e.response.data.errors) {
					Object.values(e.response.data.errors).forEach(er => {
						str += er + '<br/>'
					})
				} else if (typeof e.response.data == 'string') {
					str = e.response.data
				}
			}
			// const str = e.response.data?.ErrorMessage || e.response.data.errors?.Message[0]
			if (str) body += '<br/><br/>' + str
			return this.$store
				.dispatch('confirmer/ask', {
					title: 'Problem',
					body,
					hasCancel: false
				})
		},
	}
}