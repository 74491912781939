<template>
	<div :class="{open}">
		<transition name="fade">
			<div v-if="open" class="mobile-menu fixed-full bg-primary pt-6 px-main z-top">
				<logo class="pt-3 position-absolute t-0"></logo>
				<router-link v-for="item in links" :key="item.link" :to="item.link">{{item.label}}</router-link>
				<!-- <router-link to="/login">Login</router-link>
				<router-link to="/about">About PKF</router-link>
				<router-link to="/my-portal">How it works</router-link> 
				<router-link to="/">Contact Us</router-link>
				<router-link to="/privacy">Privacy</router-link> -->
			</div>
		</transition>
		<a href="#" class="h1 position-relative z-top lh-0" :class="{'text-white':open}" @click.prevent="open = !open"><b-icon-list v-if="!open" /><b-icon-x v-else /></a>
	</div>
</template>

<script>
import { BIconX, BIconList } from "bootstrap-vue";
export default {
	name: 'MobileMenu',
	components: {
		BIconList, BIconX
	},
	props: {
		home: Boolean
	},
	data() {
		return {
			open: false
		}
	},
	computed: {
		links() {
			const site = [
				{link: '/step1', label: 'Submit a Report'},
				{link: '/login', label: 'Login'},
				{link: '/about', label: 'About PKF'},
				{link: '/info', label: 'How it works'},
				// {link: '/', label: 'Contact Us'},
				{link: '/privacy', label: 'Privacy'},
			]; const user = [
				{link: '/my-portal', label: 'Report Portal'},
				{link: '/help', label: 'Help'},
				{link: '/my-account', label: 'Account Details'},
				{link: '/log-out', label: 'Log Out'},
			]
			return this.$store.state.saved ? user : site
		}
	},
	watch: {
		$route() {
			this.open = false
		}
	}

}
</script>

<style lang="scss" scoped>
.mobile-menu{ 
	a {
		width: 100%;
		color: #fff;
		font-size: 29px;
		display: block;
		padding: 17px 0;
		border-bottom: 1px solid white;
	}
	// .logo {
	// 	margin-top: 6px;
	// }
}
a.h1{
	color: inherit;
	font-size: 40px;
	transform: scale(1.5) translate(0px, 1px);
	display: inline-block;
	margin-bottom: 0;
}
.open a.h1{
	transform: scale(1.9) translate(-19px, -1px);
	display: inline-block;
	position: fixed !important;
}
</style>