<template>
	<b-button variant="outline-primary" class="mr-auto miw-0" @click="$router.back()">
		<span class="d-none d-md-inline">Back</span><span class="d-md-none">˂</span>
	</b-button>
</template>

<script>
export default {
	name: 'BtnBack'
}
</script>