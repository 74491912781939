<template>
	<div class="bg-primary bg-swirl">
		<b-container class="pb-5 pb-md-7 pt-5 pt-md-6 px-main">
			<b-row>
				<b-col md="8" class="text-white">
					<h1>Make your report in confidence</h1>
					<p class="sub-head">
						PKF Integrity Services is independent and has significant expertise in handling whistleblowers. If you have a concern or a matter you would like to report, you can do so here, knowing that it will be handled in the strictest of confidence and in a highly professional manner.
					</p>
					<a href="#" class="mb-3 arrow d-inline-block text-lime" @click.prevent="goto('more-container')">Read more</a><br />
					<div class="d-flex align-items-center flex-wrap">
						<b-button to="/step1" size="lg" class="d-inline-flex align-items-center p-3 pr-35 mr-3 mt-3"><icon-secure width="24" height="24" class="mr-3 mt-n1" /> Submit Report</b-button>
						<b-button to="/login" size="lg" variant="outline-white" class="d-inline-flex d-md-none align-items-center p-3 mt-3">Login</b-button>
					</div>
					<a v-if="$store.state.org.number1800" :href="`tel:${$store.state.org.number1800}`" class="h4 text-white pt-4 d-inline-block d-md-none"><icon-phone class="mr-3 text-success" /> Call {{ $store.state.org.number1800 | format1800 }}</a>
				</b-col>
				<b-col md="3" offset="1" class="d-none d-md-flex justify-content-end align-items-start">
					<client-logo class="mb-4 float-right" square />
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
import IconPhone from "./IconPhone";
export default {
	components: {IconPhone},
	methods: {
		goto(el) {
			const yOffest = this.$store.getters.isMobile() ? 0 : 84
			el = document.getElementById(el)
			const top = el.getBoundingClientRect().top + window.scrollY - yOffest;
			window.scrollTo({top, behavior: "smooth"})
		}
	},
}
</script>