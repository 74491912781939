<template>
	<b-button variant="outline-primary" class="mr-3" :disabled="saving" @click="onClick">{{label}}</b-button>
</template>

<script>
import canCreatePassword from "@/mixins/canCreatePassword";
export default {
	name: 'BtnSave',
	mixins: [canCreatePassword],
	data() {
		return {
			label: 'Save',
			saving: false
		}
	},
	methods: {
		onClick() {
			if (this.$store.state.saved) {
				this.save()
			} else {
				this.$store.commit('login/set', {state: 'save'})
				this.createPassword()
			}
		},
		save() {
			this.saving = true
			this.label = 'Saving'
			this.$store.dispatch('save')
			this.$emit('save')
			setTimeout(() => {
				this.label = 'Saved'
				setTimeout(() => {
					this.saving = false
					this.label = 'Save'
				}, 1000);
			}, 1200);
		}
	}
}
</script>

