<template>
	<div class="d-flex flex-column mx-n3 mx-sm-0">
		<div v-if="hasChat" class="group-selection d-flex align-items-center justify-content-start bg-pink py-3 pl-35">
			<span id="select-chat-label" class="d-none d-sm-inline-block fs-15 bold mr-3 no-wrap">Select Chat:</span>
			<b-button
				v-for="item in chats"
				:key="item.value"
				pill
				:variant="item.variant"
				class="mr-3 d-none d-xxl-block"
				:class="{active: item.value == activeChat}"
				@click="switchChats(item.value)"
			>
				{{ item.text }} <sup v-if="unread[item.value]">{{unread[item.value]}}</sup>
			</b-button>
			<b-form-select v-model="activeChat" :options="chats" class="d-xxl-none mr-35" @change="switchChats"></b-form-select>
		</div>
		<div v-else class="group-selection d-flex align-items-center justify-content-start bg-pink py-3 pl-35">
			<h4>System Messages</h4>
		</div>
		<div ref="chat" class="chat-window oy-auto border bg-white fs-14 px-3 px-md-35 pt-3">
			<transition name="fade" mode="out-in" @enter="scrollWindow">
				<div :key="activeChat">
					<template v-for="msg in messages">
						<div :key="msg.date" class="date-wrapper">
							<div class="date">
								{{ msg.date }}
							</div>
							<div class="line"></div>
						</div>
						<div
							v-for="(item, index) in msg.items"
							:key="msg.date + '-' + index"
							class="message mb-3"
							:class="'msg-' + item.user"
						>
							<div class="user">
								{{ userName(item)
								}}<span>{{ item.createdUtc | formatDate('DD/MM HH:mm') }} (AEST)</span>
							</div>
							<div v-if="messageIsFileLink(item.message)" class="message-body" v-html="item.message" />
							<div v-else class="message-body">{{item.message}}</div>
						</div>
					</template>
					<div v-if="loading" class="text-center p-3">Loading...</div>
					<div v-else-if="!connected" class="text-center p-3">Connecting...</div>
					<div v-else-if="!messages.length" class="text-center p-3">No messages in this chat.</div>				
				</div>
			</transition>
		</div>
		<div v-if="chat && chat.warning" class="warning bold px-3 px-md-35 py-3 bg-orange text-warning">
			<span class="mr-3">⚠</span>{{ chat.warning }}
		</div>
		<div v-if="canSend" class="d-flex flex-column">
			<b-form-textarea
				ref="message"
				v-model="message"
				class="w-100 px-3 px-md-35 py-3 bg-pink"
				placeholder="Type a message"
				rows="2"
				@keydown.enter.exact.prevent="send"
			/>
			<b-button :disabled="!connected" pill class="my-3 ml-auto mr-3 mr-sm-0" @click="send">Send</b-button>
		</div>
		<div v-if="!isClosed" class="mx-3 mx-sm-0 fs-13 text-mid-gray mt-3">{{chat.footer}}</div>
		<div v-else class="mx-3 mx-sm-0 fs-13 text-mid-gray mt-3">This report has been closed, you can not add messages to this chat.</div>
	</div>
</template>

<script>
import {isChat} from '@/mixins'

export default {
	name: 'Chat',
	mixins: [isChat],
	data() {
		return {
			messagesRaw: [],
			message: '',
			allChats: [],
			activeChat: 0,
			unread: [0, 0, 0, 0],
			loading: true,
		}
	},
	computed: {
		chats() {
			const system = { value: 4, text: 'System Messages', variant: 'brand' }
			const privatePKF = { value: 1, text: 'Private PKF', warning: 'Private Chat with PKF', variant: 'pkf', footer: `Messages left here are private between you and PKF.` }
			if (this.reportStatus < 2 && this.org.serviceOption) system.footer = `Chat will become available once the report has been reviewed.`
			if (!this.hasChat) return [system]
			else if (!this.canMessageClient) return [privatePKF, system]
			return [
				{ value: 0, text: 'Group Chat', warning: `Shared Messages ${this.orgName} and PKF`, variant: 'light', footer: `Messages left here can be seen by both ${this.orgName} and PKF. If you wish to talk privately with either ${this.orgName} or PKF, select the appropriate tab.` },
				privatePKF,
				{ value: 2, text: 'Private ' + this.orgName, warning: `Private Chat with ${this.orgName}`, variant: 'brand', footer: `Messages left here are private between you and ${this.orgName}.` },
				system,
			]
		},
		chat() {
			return this.chats.find(c => c.value == this.activeChat) || {}
		},
		messages() {
			let curDate, prevDate
			const d = []
			this.messagesRaw.forEach(msg => {
				let date = new Date(msg.createdUtc).format('Do MMMM YYYY')
				if (date == new Date().format('Do MMMM YYYY')) date = 'Today'
				if (date != prevDate) {
					curDate = { date, items: [] }
					d.push(curDate)
				}
				curDate.items.push(msg)
				if (msg.user == undefined) msg.user = 3
				msg.message = msg.message.replaceAll('&lt;', '<')
				prevDate = date
			})
			return d
		},
		reportStatus() {
			return this.$store.state.report?.status
		},
		isClosed() {
			return [4, 5].includes(this.reportStatus)
		},
		canSend() {
			return !this.isClosed && this.activeChat != 4
		},
		canMessageClient() {
			return this.reportStatus != 6
		}
		// {value: 0, text: 'Draft'},
		// {value: 1, text: 'Awaiting Review'},
		// {value: 2, text: 'Active'},
		// {value: 3, text: 'Referred'},
		// {value: 4, text: 'Closed'},
		// {value: 5, text: 'Archived'},
		// {value: 6, text: 'Withheld'},

	},
	watch: {
		// activeChat() {
		// 	this.loadChat()
		// },
		// messagesRaw() {
		// 	this.scrollWindow()
		// }
	},
	mounted() {
		if (!this.hasChat || !this.canMessageClient) this.activeChat = 4
		else if (this.$route.query.chat) this.activeChat = Number(this.$route.query.chat)
		this.loadChat()
		console.log('CHAT CREATED');
	},
	methods: {
		loadChat() {
			this.loading = true
			const cached = this.allChats[this.activeChat]
			if (cached) return this.chatLoaded(cached)
			this.messagesRaw = []
			const api = this.$store.getters.apiDisclosurePath + (this.activeChat == 4 ? 'system-message' : 'chat-history')
			this.$http
				.get(api, {params: {messagetype: this.activeChat}})
				.then(response => {
					this.chatLoaded(response.data)
					this.allChats[this.activeChat] = response.data
					this.scrollWindow()
				})
		},
		chatLoaded(chat) {
			this.loading = false
			this.messagesRaw = chat
		},
		userName(msg) {
			return ['Me', 'PKF', this.orgName, 'System'][msg.user]
		},
		switchChats(index) {
			console.log('switchChats', index);
			this.activeChat = index
			this.$set(this.unread, index, 0)
			this.loadChat()
		},
		addNewline() {
			this.message += '<br/>'
		},
		// send() {
		// 	this.messagesRaw.push({
		// 		id: ++this.rubbishID,
		// 		date: new Date().toUTCString(),
		// 		// msg: this.message.replace('\n', '<br/>'),
		// 		message: this.message.replace('\n', '<br>'),
		// 		user: 'user'
		// 	})
		// 	this.message = ''
		// 	this.scrollWindow()
		// },
		scrollWindow() {
			this.$nextTick(() => {
				if (this.$refs.chat) { this.$refs.chat.scrollTop = this.$refs.chat.scrollHeight }
			})
		},
		messageIsFileLink(message) {
			return message.match(/^<a href="\/dms\//)
		},

	}
}
</script>

<style lang="scss">
.chat-window {
	height: 500px;
	// mobile height to fit screen with bars.
	max-height: calc(100vh - 209px);
	> div {
		display: flex;
		flex-direction: column;
		scroll-behavior: smooth;
		position: relative;
		min-height: 480px;
		> :first-child {
			margin-top: auto !important;
		}
	}
	.date-wrapper {
		display: flex;
		align-items: center;
		margin: 10px 0;
	}
	.date {
		font-size: 12px;
		background: $gray-300;
		display: inline-block;
		padding: 0 5px;
	}
	.line {
		flex-grow: 1;
		border-bottom: 1px solid $gray-300;
		height: 0;
	}
	.user {
		font-weight: bold;
		font-size: 12px;
		span{
			font-weight: normal;
		}
	}
	.message:not(.msg-0) {
		text-align: left;
		width: 460px;
		max-width: 85%;
		.user {
			display: inline-block;
		}
		.user:before {
			content: "";
			margin-right: 9px;
			display: inline-block;
			border-radius: 50%;
			width: 10px;
			height: 10px;
			background: currentColor;
		}
	}
	.message .user span {
		margin-left: 5px;
	}
	.msg-1 .user {
		color: $primary;
	}
	.msg-2 .user {
		color: var(--brand);
	}
	// system
	.msg-3 .user {
		color: orange;
	}
	.msg-0 {
		width: 520px;
		max-width: 85%;
		background: $light-blue;
		padding: 17px 20px;
		align-self: flex-end;
		margin-right: 15px;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			left: 100%;
			top: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 15px 15px 0 0;
			border-color: $light-blue transparent transparent transparent;
		}
	}
	.message-body {
		white-space: pre-wrap;
	}
}
.group-selection .rounded-pill {
	background: none;
	border: none;
	color: black;
	&:hover {
		background: white;
	}
	&.active {
		color: white !important;
	}
	&.btn-light.active{
		background: #333;
	}
	&.btn-brand{
		&.active{
			background: var(--brand);
		}
		&:hover{
			color: var(--brand);
		}
	}
	&.btn-pkf{
		&.active {
			background: $primary;
		}
		&:hover {
			color: $primary;
		}
	}
	// &:hover {
	// 	background: rgba($primary, 0.8);
	// }
	// &.active {
	// 	background: $primary !important;
	// }
}
.text-warning {
	@include media-breakpoint-down(xs) {
		font-size: 14px;
		span {
			font-size: 16px;
		}
	}
}
#select-chat-label{
	@media screen and (min-width: 1700px) and (max-width: 1870px)  {
		display: none !important;
	}
}
</style>