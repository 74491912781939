export default {
	computed: {
		org() {
			return this.$store.state.org
		},
		hasChat() {
			return this.org.serviceOption && this.$store.state.report.status > 1
		}
	},
}
