<template>
	<div>
		<div class="d-none d-sm-flex">
			<div v-for="(item, index) in items" :key="index" class="tab" :class="{active:value==index}" @click="$emit('input', index)">
				{{item}}
			</div>
		</div>
		<hr class="mt-0 d-none d-sm-block">
		<b-form-select class="d-sm-none" :value="value" :options="selectItems" @change="$emit('input', $event)"></b-form-select>
	</div>
</template>

<script>
export default {
	name: 'TabNav',
	props: {
		items: Array,
		value: Number
	},
	computed: {
		selectItems() {
			return this.items.map((item, index) => ({
				text: item,
				value: index
			})) 
		}
	},
}
</script>

<style lang="scss">
.tab{
	color: #959595;
	margin-right: 8%;
	cursor: pointer;
	position: relative;
	white-space: nowrap;
	user-select: none;
	&.active{
		color: $success;
		font-weight: bold;
		&::after{
			content: '';
			position: absolute;
			bottom: -1.5px;
			left: 0;
			right: 0;
			height: 2px;
			background-color: $success;
		}

	}
}
</style>