const events = 'mousedown click mousemove keydown mousewheel'

// TODO: add _.debounce function
export default timeout => {
	// console.log('checking activity');
	let timer
	return new Promise(resolve => {
		// RESOLVE if there are any events
		const handleEvent = () => {
			// console.log('user - some activity');
			clearTimeout(timer)
			startTimer()
		}
		const removeListeners = () => {
			events.split(" ")
				.map(name => window.removeEventListener(name, handleEvent, false));
		}
		// REJECT If it makes it to timeout with no events
		const startTimer = () => {
			timer = setTimeout(() => {
				// console.log('user - no activity');
				removeListeners()
				resolve()
			}, timeout);
		}
		// add events
		events.split(" ")
			.map(name => window.addEventListener(name, handleEvent, false));
		
		startTimer()
	})
}
