<template>
	<nav>
		<router-link
			v-for="link in links"
			:key="link.to"
			:to="link.to"
			:class="{visited: visited || link.visited}"
		>
			<div />
			{{ link.label }}
		</router-link>
	</nav>
</template>

<script>
export default {
	name: 'SubmitProgress',
	props: {
		visited: Boolean,
	},
	computed: {
		links() {
			return this.$store.getters['steps/steps']
		}
	},
	// watch: {
	// 	$route(newVal, old) {
	// 		console.log('route old', old)
	// 		const oldLink = this.links.find(link => link.to == old.path)
	// 		oldLink.visited = true
	// 	},
	// },
	created () {
		if (this.visited) this.$store.dispatch('steps/allVisited')
	},
}
</script>

<style lang="scss" scoped>
$width: 30px;

%green {
	color: $success;
	&::before,
	div::before {
		border-color: $success;
	}
}
%unvisited {
	pointer-events: none;
	color: $light;
	div::before {
		color: $light;
		content: counter(section);
		background-color: #fff;
		border-color: $light;
	}
	&::before {
		border-color: $light;
	}
}
%visited {
	@extend %green;
	pointer-events: all;
	div::before {
		content: '✓';
		font-weight: bold;
		background: $success;
		color: white;
	}
}
%active {
	@extend %green;
	font-weight: bold;
	pointer-events: none;
	div::before {
		content: counter(section);
		background: white;
		font-weight: bold;
		color: $success;
	}
}
// going to make the base state unvisited
// then other states can override with .active and .visited
nav {
	counter-reset: section;
	a {
		display: block;
		counter-increment: section; /* Increment the value of section counter by 1 */
		margin-bottom: 2em;
		// white-space: nowrap;
		line-height: 1;
		@extend %unvisited;
		div {
			display: inline-block;
			&::before {
				font-size: 1rem;
				border-radius: 50%;
				border-width: 2px;
				border-style: solid;
				width: $width;
				display: inline-block;
				text-align: center;
				margin-right: 20px;
				height: $width;
				line-height: 1.7;
			}
		}
		&.router-link-active {
			@extend %active;
		}
		&.visited {
			@extend %visited;
		}
		&:visited {
			background: #000 !important;
		}
		@include media-breakpoint-down(sm) {
			text-align: center;
			flex-grow: 1;
			position: relative;
			font-size: 15px;
			margin-bottom: 0;
			padding: 0 5px;
			flex-basis: 0;
			&:first-child {
				padding-left: 0;
			}
			&:last-child {
				padding-right: 0;
			}
			div {
				display: block;
				&::before {
					margin: 0 auto 5px;
				}
			}
			&:not(:first-child)::before {
				content: '';
				position: absolute;
				top: #{$width/2};
				right: 50%;
				width: 100%;
				border-bottom-width: 2px;
				border-bottom-style: solid;
				z-index: -1;
			}
		}
		@include media-breakpoint-down(xs) {
			font-size: 13px;
			flex-basis: 0;
		}
	}
}
</style>
