export default {
	data() {
		return {
			isValid: false,
			showValidation: true
		}
	},
	methods: {
		validateAll() {
			return new Promise(resolve => {
				this.$nextTick(() => {
					const first = document.querySelector('.is-invalid input')
					if (first) {
						first.focus()
						return resolve(false)
					}
					resolve(true)
				})
			})
		}
	}
}