import setState from "../common/setState";

const state = () => ({
	isStarted: false
})

export default {
	namespaced: true,
	mutations: setState,
	state,
}