import Vue from 'vue'
import Vuex from 'vuex'
import { login, steps, questions, chat, confirmer } from './modules'
import axios from 'axios'
import setState from "./common/setState"
import { v4 as uuidv4 } from 'uuid'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		disclosureId: '',
		activityTimer: false,
		modalState: 'save',
		saved: false,
		routeMeta: {},
		org: {},
		report: {},
		isRetrieving: false,
		guid: null,
		isLive: false,
		savedWithoutPassword: false,
		isOptus: false
	},
	mutations: {
		addDocs(state, docs) {
			Vue.set(state.report, 'additionalDocuments', docs)
		},
		setServiceOption(state, serviceOption) {
			Vue.set(state.org, 'serviceOption', serviceOption)
		},
		...setState
	},
	actions: {
		init({commit, getters, state}, org) {
			org = org.toUpperCase()
			if ((state.isLive && org == 'OPTUS') || (!state.isLive && org == 'OPT')) commit('set', {isOptus: true})
			window.sessionStorage.setItem('org', org)
			const url = getters.apiBasePath + 'organizations/' + org + '/landing-page'
			return axios.get(url).then(response => {
				const o = response.data
				if (o.divisions && o.divisions.length) o.divisions.unshift({id: null, name: 'Choose Division'})
				o.selectedDivision = null
				o.code = org
				document.documentElement.style.setProperty('--brand', o.clientColour)
				if (o.regional1800?.length) o.number1800 = o.regional1800.find(r => r.region == "AU")?.number
				if (o.logo) o.logo = `${state.isLive ? o.logo : 'https://dmstest.com'}/dms/logos/${o.code}/${o.logo}`
				if (org == 'DFLT') o.orgName = 'the organisation'
				commit('set', {org: o})
			})
		},
		save({state, getters, commit, dispatch}) {
			const o = {
				orgCode: state.org.code,
				password: state.login.password,
				channel: 0,
				isSaveOnly: state.login.state == 'save',
				discloserEmail: state.login.email || null,
				discloserMobile: state.login.mobile,
				discloserName: state.login.name,
				isAnonymous: state.login.anon,
				divisionId: state.org.selectedDivision,
				answeredCaseBackgroundQuestions: getters['questions/responses']('background'),
				answeredCaseDetailsQuestions: getters['questions/responses']('details'),
				answeredAdditionalQuestions: getters['questions/responses']('additional')
			}
			console.log('save', o);
			if (!state.saved) {
				if (state.report && state.report.additionalDocuments) {
					o.attachedDocs = state.report.additionalDocuments.map(d => d.fileId)
					o.tempDocsFolder = state.guid
				}
				return axios.post(getters.apiPath + o.orgCode + '/create', o, {
					headers: {
						Cookie: "X-Access-Token=null"
					} 
				}).then(response => {
					commit('set', {disclosureId: response.data, saved: true, savedWithoutPassword: !o.password})
					if (o.isSaveOnly) dispatch('login/login', {password: state.login.password, disclosureId: response.data})
				})
			} else {
				return axios.put(getters.apiPath + state.disclosureId + '/save', o)
			}
		},

		// !! this unused as yet
		loadReport({state, commit, getters}) {
			return axios
				.get(getters.apiDisclosurePath + 'report-details')
				.then(response => {
					commit('set', {report: {...response.data, isLoaded: true}})
				})
		},
		getDocs({state, commit, getters}) {
			return axios
				.get(getters.apiDisclosurePath + 'docs')
				.then(response => {
					commit('addDocs', response.data)
				})
		},
		createGUID({state, commit, getters}) {
			return new Promise(resolve => {
				if (state.guid) return resolve(state.guid)
				const guid = getters.newGUID()
				commit('set', {guid})
				resolve(guid)
			})
		}
	},
	modules: {
		login, steps, questions, chat, confirmer
	},
	getters: {
		newGUID: () => () => uuidv4(),
		isMobile: () => () => window.innerWidth < 850,
		apiBasePath: () => '/dms/api/',
		apiPath: (state, getters) => getters.apiBasePath + 'disclosure/',
		apiDisclosurePath: (state, getters) => getters.apiPath + state.disclosureId + '/',
		apiClientPath: (state, getters) => `${getters.apiBasePath}organizations/${state.org.code}/`
	},
})
