<template>
	<div>
		<a v-if="!open" href="#" class="arrow" @click.prevent="open = true">Upload Documents</a>
		<b-form v-else @submit.prevent="submit">
			<b-form-group
				:label="label"
				label-for="upload"
			>
				<b-form-file id="upload" v-model="files" :accept="acceptTypes" multiple placeholder="Drag files here or browse"></b-form-file>
				<div class="fs-13 mt-1 text-mid-gray"><p>Allowed file types: PDF, DOCX, XLSX, PPTX, JPG, PNG, MP3 and MP4</p></div>
			</b-form-group>
			<div class="d-flex align-items-center">
				<b-button
					class="mr-auto sm"
					variant="outline-primary"
					@click="open = false"
				>
					Close
				</b-button>
				<b-button type="submit" class="sm">Upload</b-button>
				</div>
		</b-form>
	</div>
</template>

<script>
import { fileUploadType } from "@/mixins";
export default {
	name: 'UploadFile',
	mixins: [fileUploadType],
	props: {
		label: 
		{
			type: String,
			default: 'Upload Files'
		}
	},
	data() {
		return {
			open: false,
			files: []
		}
	},
	methods: {
		submit(e) {
			console.log('uploadFile submit', this.files);
			this.$emit('upload', this.files);
			this.files = [];
		}
	}
	
}
</script>