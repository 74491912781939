<template>
	<div class="spinner" :class="{relative}" />
</template>

<script>
export default {
	name: 'Spinner',
	props: {relative: Boolean}
}
</script>

<style lang="scss">
// spinner css defined in index.html for app preloader
.spinner.relative{
	position: relative;
	transform: scale(30%);
	display: inline-block;
}
</style>