<template>
	<div class="home-header px-main py-3 fixed-top d-flex align-items-center shadow">
		<Logo class="mr-auto mr-sm-4 z-100"></Logo>
		<img v-if="$store.state.isOptus" src="/img/optus.png" alt="" class="d-none d-sm-block">
		<img v-if="$store.state.org.code == 'DET'" src="/img/home/22-018 Speak Up_White.png" alt="" class="det_banner mr-auto d-none d-sm-inline" />

		<div class="d-none d-md-flex align-items-center ml-auto">
			<Number1800></Number1800>
			<user-menu v-if="$store.state.login.isLoggedIn" />
			<template v-else>
				<b-button to="/login" :variant="variants.btnLogin">Login</b-button>
			</template>
		</div>
		<mobile-menu class="d-md-none ml-auto" home />
	</div>
</template>

<script>
import { UserMenu, Number1800 } from './ui'
export default {
	name: 'HomeHeader',
	components: {
		UserMenu,
		Number1800,
	},
	computed: {
		variants() {
			return this.$store.state.isOptus
				? {
					btnLogin: 'outline-primary',
				}
				: {
					btnLogin: 'outline-white',
				}
		},
	},
}
</script>

<style lang="scss">
.home-header {
	// padding-top: 21px;
	// padding-bottom: 21px;
	background: var(--home-header-bg);
	color: var(--home-header-color);
	height: $home-header-h;
	select {
		background-color: #eaeef6;
	}
	@include media-breakpoint-down(sm) {
		box-shadow: none !important;
		position: relative !important;
		height: auto;
	}
	// @include media-breakpoint-up(md){
	// 	position: fixed;
	// 	z-index: 100;
	// }
	.det_banner {
		max-width: 380px;
		width: 60%;
	}
}
</style>
