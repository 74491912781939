<template>
	<b-button v-bind="props">{{text}}</b-button>
</template>

<script>
export default {
	name: 'BtnSubmit',
	props: {
		label: String,
		saving: Boolean,
		isSave: Boolean,
		disabled: Boolean,
		size: String,
		saveLabel: {
			type: String,
			default: 'Saving'
		}
	},
	computed: {
		text() {
			return this.saving 
				? this.saveLabel
				: this.label 
					? this.label
					: 'Save'
		},
		props() {
			const o = {}
			if (this.isSave) o.variant = 'outline-warning'
			else o.type = 'submit'
			if (this.disabled || this.saving) o.disabled = true
			o.size = this.size
			return o
		}
	},
	// methods: {
	// 	onClick() {
	// 		if (this.$store.state.saved) {
	// 			this.save()
	// 		} else {
	// 			this.$store.commit('login/set', {state: 'save'})
	// 			// this.createPassword()
	// 		}
	// 	},
	// 	save() {
	// 		this.saving = true
	// 		this.label = 'Saving'
	// 		this.$store.dispatch('save')
	// 		setTimeout(() => {
	// 			this.label = 'Saved'
	// 			setTimeout(() => {
	// 				this.saving = false
	// 				this.label = 'Save'
	// 			}, 1000);
	// 		}, 1200);
	// 	}
	// }
}
</script>

