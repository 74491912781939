<template>
	<div class="case-details bg-pink mx-n3 mx-sm-0">
		<div class="py-2 bg-white d-flex justify-content-center">
			<client-logo class="px-3 px-sm-35" />
		</div>
		<div class="bg-light-blue p-3 px-sm-35 fs-14">
			STATUS: <span class="ml-2">{{statusText}}</span>
		</div>
		<div class="p-3 px-sm-35">
			<p class="mb-2"><b>Client:</b> {{$store.state.org.orgName}}</p>
			<p v-if="report.division" class="mb-2"><b>Division:</b> {{report.division}}</p>
			<p class="mb-2"><b>Report ID:</b> {{$store.state.disclosureId}}</p>
			<p class="mb-2"><b>Date Lodged:</b> {{report.lodgedDate | formatDate}}</p>
			<div class="my-3 py-3 download-report fs-15">
				<a href="#" @click.prevent="downloadReport"><b-icon-download class="mr-3" />Download Report</a>
			</div>
			<p class="bold">Additional Documents</p>
			<div v-for="(file, index) in files" :key="index" class="doc-download" :class="{'text-mid-gray': file.state}">
				<a href="#" @click.prevent="downloadFile(file)"><b-icon-file-text-fill class="mr-2" /><span class="nowrap">{{file.fileName}}</span></a>
				<div v-if="file.state" class="fs-14">{{file.state}}</div>
			</div>
			<p class="mt-35 font-italic text-mid-gray">Want to add more information to the report?</p>
			<upload-file @upload="checkFiles" />
		</div>
	</div>
</template>

<script>
import { BIconDownload, BIconFileTextFill } from 'bootstrap-vue'
import { UploadFile } from '@/components/ui'
import ClientLogo from './ui/ClientLogo.vue'
import {canDownloadReport, canUpload} from '@/mixins'
export default {
	name: 'CaseDetails',
	components: {
		BIconDownload,
		BIconFileTextFill,
		UploadFile,
		ClientLogo,
	},	
	mixins: [canDownloadReport, canUpload],
	computed: {
		status() {
			return [
				{value: 0, text: 'Draft'},
				{value: 1, text: 'Awaiting Review'},
				{value: 2, text: 'Active'},
				{value: 3, text: 'Referred'},
				{value: 4, text: 'Closed'},
				{value: 5, text: 'Archived'},
				{value: 6, text: 'Unreleased'},
			][this.report.status]
		},
		statusText() {
			return this.status?.text
		}
	}
}
</script>

<style lang="scss">
.case-details {
	position: sticky;
	top: 30px;
	.client-logo{
		max-height: 70px !important;
	}
}
.doc-download {
	margin-bottom: 0.5rem;
	a {
		font-size: 15px;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		&:hover{
			text-decoration: none;
		}
	}
	&.text-mid-gray a{
		color: $gray-500;
	}
}
</style>