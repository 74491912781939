import setState from "../common/setState";
import Vue from 'vue'
const state = () => ({
	steps: [
		{ to: '/step1', label: 'Case Background', visited: false },
		{ to: '/step2', label: 'Case Details', visited: false },
		{ to: '/step3', label: 'Additional Information', visited: false, additional: true },
		{ to: '/review', label: 'Review', visited: false },
	],
})

const mutations = {
	...setState,
	reset(state) {
		state.steps.forEach(step => step.visited = false)
	},
	markAsVisited(state, path) {
		const step = state.steps.find(link => link.to == path)
		Vue.set(step, 'visited', true)
	}
}
 
const actions = {
	allVisited({state}) {
		state.steps.forEach(step => step.visited = true)
	},
}

const getters = {
	steps: (state, getters, rootState) => {
		return rootState.org.hasAdditionalQuestions
			? state.steps
			: state.steps.filter(s => !s.additional)
	}
}

export default {
	namespaced: true,
	mutations,
	getters,
	state,
	actions,
}