<template>
	<b-form-select v-model="selected" :options="options" size="sm" class="w-auto ml-3" @change="selectChanged" />
</template>

<script>
export default {
	name: 'SelectLoggedIn',
	data() {
		return {
			selected: 'my-portal',
			options: [
				{ value: 'my-portal', text: 'My Report' },
				{ value: 'my-account', text: 'Account Details' },
				{ value: 0, text: 'Log Out' },
			]
		}
	},
	methods: {
		selectChanged(e) {
			if (e) {
				this.$router.push(`/${e}`)
			} else {
				this.logOut()
			}
		},
		logOut() {
			this.$router.push('/')
			window.location.reload()
		}
	}
}
</script>