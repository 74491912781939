import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SubmitReportUI from '../views/SubmitReportUI.vue'

Vue.use(VueRouter)
const mainUImeta = {
	key: 'mainUI',
	showMenu: true
}
const plainUIMeta = {
	homeHeader: true, noLeftCol: true, bgWhite: true
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			key: 'home', ...plainUIMeta
		},
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: { key: 'about', ...plainUIMeta }
	},
	{
		path: '/report',
		name: 'report',
		component: SubmitReportUI,
		redirect: '/step1',
		children: [
			{
				path: '/step1',
				name: 'Step1',
				component: () => import(/* webpackChunkName: "step1" */ '../views/Step1.vue'),
				meta: mainUImeta
			},
			{
				path: '/step2',
				name: 'Step2',
				// route level code-splitting
				// this generates a separate chunk (step2.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "step2" */ '../views/Step2.vue'),
				meta: {...mainUImeta, noLand: true}
			},
			{
				path: '/step3',
				name: 'Step3',
				// route level code-splitting
				// this generates a separate chunk (step3.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "step3" */ '../views/Step3.vue'),
				meta: {...mainUImeta, noLand: true}
			},
			{
				path: '/review',
				name: 'Review',
				// route level code-splitting
				// this generates a separate chunk (review.[hash].js) for this route
				// which is lazy-loaded when the route is visited.
				component: () => import(/* webpackChunkName: "review" */ '../views/Review.vue'),
				meta: {...mainUImeta, noLand: true}
			},
		],
	},
	{
		path: '/thanks',
		name: 'Thanks',
		// route level code-splitting
		// this generates a separate chunk (thanks.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>	import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
		meta: { key: 'thanks' }
	},
	{
		path: '/login',
		name: 'Login',
		// route level code-splitting
		// this generates a separate chunk (login.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		meta: {key: 'login'}
	},
	{
		path: '/my-account',
		name: 'MyAccount',
		// route level code-splitting
		// this generates a separate chunk (my-account.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "my-account" */ '../views/MyAccount.vue'),
		meta: {key: 'account', requiresLogin: true}
	},
	{
		path: '/my-portal',
		name: 'MyPortal',
		// route level code-splitting
		// this generates a separate chunk (my-portal.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "my-portal" */ '../views/MyPortal.vue'),
		meta: {key: 'portal', LeftColBlue: true, fullWidth: true, bgWhite: true, portal: true, requiresLogin: true}
	},
	{
		path: '/create-password',
		name: 'CreatePassword',
		// route level code-splitting
		// this generates a separate chunk (create-password.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "create-password" */ '../views/CreatePassword.vue'),
		meta: {key: 'createPass'}
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		// route level code-splitting
		// this generates a separate chunk (forgot-password.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue'),
		meta: {key: 'forgotPass'}
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		// route level code-splitting
		// this generates a separate chunk (reset-password.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue'),
		meta: {key: 'resetPass'}
	},
	{
		path: '/help',
		name: 'Help',
		// route level code-splitting
		// this generates a separate chunk (help.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "help" */ '../views/Help.vue'),
		meta: { key: 'help', ...plainUIMeta }
	},
	{
		path: '/guide',
		name: 'Guide',
		// route level code-splitting
		// this generates a separate chunk (guide.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "guide" */ '../views/Guide.vue'),
		meta: { key: 'guide', ...plainUIMeta }
	},
	{
		path: '/terms',
		name: 'Terms',
		// route level code-splitting
		// this generates a separate chunk (terms.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
		meta: { key: 'terms', ...plainUIMeta }
	},
	{
		path: '/info',
		name: 'Info',
		// route level code-splitting
		// this generates a separate chunk (info.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/Info.vue'),
		meta: { key: 'info', ...plainUIMeta }
	},
	{
		path: '/whoops',
		name: 'Whoops',
		// route level code-splitting
		// this generates a separate chunk (whoops.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "whoops" */ '../views/Whoops.vue'),
		meta: { key: 'whoops', ...plainUIMeta }
	},
	{ path: '*', redirect: '/' }

]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		let position
		console.log('router scroll', savedPosition);
		if (savedPosition) {
			position = savedPosition
		} else if (to.hash) {
			position = {selector: to.hash, offset: {x: 0, y: 70}}
		} else {
			position = { x: 0, y: 0 }
		}
		// console.log('router scrollBehaviour!', to, from, position);
		
		if (from.fullPath == to.fullPath) return position

		return new Promise(resolve => {		
			// wait for the out transition to complete (if necessary)
			this.app.$root.$once('triggerScroll', () => {
				// if the resolved position is falsy or an empty object,
				// will retain current scroll position.
				// console.log('index triggerscroll', position);
				
				resolve(position)
			})
		})
	}

})

export default router
