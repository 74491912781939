<template>
	<div id="app" class="loaded minh-100 d-flex flex-column" :class="{'bg-white': $route.meta.bgWhite, 'has-home-header':$route.meta.homeHeader, optus: $store.state.isOptus }">
		<transition name="fade" mode="out-in">
			<HomeHeader v-if="$route.meta.homeHeader" key="hh" />
			<Header v-else key="h" />
		</transition>
		<div class="d-flex flex-grow-1 flex-column flex-md-row minh-100vh">
			<template v-if="!$store.state.routeMeta.noLeftCol">
				<transition name="fade">
					<LeftCol v-if="!$route.meta.noLeftCol" />
					<!-- <LeftCol v-if="!$store.state.routeMeta.noLeftCol" /> -->
				</transition>
			</template>
			<transition name="fade" mode="out-in" @enter="$root.$emit('triggerScroll')">
				<router-view :key="$route.meta.key" class="d-flex flex-grow-1 flex-column" />
			</transition>
			<spinner />
		</div>
		<Footer />
		<create-password-modal />
		<timeout-modal />
		<confirm-modal />
	</div>
</template>

<script>
import '@/styles/main.scss'
import { Footer, CreatePasswordModal, Header, HomeHeader, LeftCol, TimeoutModal, ConfirmModal } from '@/components'
import { checkIE } from "@/mixins";
export default {
	components: { 
		Footer,
		Header,
		HomeHeader,
		LeftCol,
		CreatePasswordModal,
		TimeoutModal, 
		ConfirmModal
	},
	mixins: [checkIE],
	watch: {
		// '$store.state.routeMeta'(newval) {
		// 	console.log('app routemeta changed', newval);
		// },
		$route(newVal, oldVal) { 
			// console.log('APP route changed', newVal, oldVal);
			this.checkLoggedIn() 
			// if you go direct to a page flagged with noLand, or the details questions have not been loaded, go home
			if (newVal.meta.noLand && (!oldVal.name || !this.$store.state.questions.background.length)) this.$router.push('/')
		}
	},
	mounted() {
		// console.log('APP MOUNTED');
		this.$store.commit('set', {routeMeta: this.$route.meta})
		this.$root.$on('triggerScroll', () => {
			// console.log('onTriggerScroll', this.$route.meta);
			this.$store.commit('set', {routeMeta: this.$route.meta})
		})
	},
	methods: {
		checkLoggedIn() {
			if (this.$route.meta.requiresLogin && !this.$store.state.login.isLoggedIn) {
				this.$router.push('/login?redirect=' + this.$route.fullPath)
				console.log(this.$route);
			}
		}
	},
	// beforeRouteEnter() {
	// 	this.checkLoggedIn()
	// },
	metaInfo() {
		if (this) {
			return {
			// if no subcomponents specify a metaInfo.title, this title will be used
				title: 'PKF ' + this.term,
				// all titles will be injected into this template
				titleTemplate: '%s | PKF ' + this.term
			} 
		}
	}
}
</script>

<style lang="scss">
#app {
	transition: background-color $transition-duration;
	&:not(.bg-white){
		@include media-breakpoint-up(md) {
			background-color: $secondary;
		}
	}
}
</style>